<template>
  <div id="FeedbackResumeDetail">
    <p class="stick" @click="toggleFeedback">Tous les retours</p>
    <div class="content" ref="review">
      <div class="cont-review" ref="innerreview">
        <div class="review">
          <div
            class="inner-review"
            v-if="previewFormat.FormatEvenements.length > 0"
          >
            <div
              class="item"
              v-for="(review, index) in previewFormat.FormatEvenements"
              :key="index"
              :class="review.User.brand === 'MadMix Digital' ? 'MMD' : 'CLIENT'"
            >
              <p class="txt-comment" v-if="review.text">{{ review.text }}</p>
              <p class="txt-comment" v-else>Pas de texte dans ce commentaire</p>
              <div class="comment-info">
                <p class="user">
                  by {{ review.User.firstName }} on
                  {{ pgFormatDate(review.createdAt) }}
                </p>
              </div>
            </div>
          </div>
          <div v-else class="inner-review">Pas de retours</div>
        </div>
        <!-- <div class="addReview" v-if="auth">
          <textarea name="review" id="review" cols="30" rows="10" v-model="text"></textarea>
          <div @click="addCommentToFormat">Add a comment</div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FeedbackResumeDetail",
  props: ["toggleFeedback"],
  computed: {
    ...mapState({
      previewFormat: state => state.Home.PreviewFormat,
      ActiveUser: state => state.Home.ActiveUser
    })
  },
  data() {
    return {
      deploy: true,
      newCampaignReview: "",
      seePreviewProject: false
    };
  },
  methods: {
    seeOldProject() {
      this.seePreviewProject = !this.seePreviewProject;
    },
    saveReview() {
      this.$store.dispatch("SAVE_ALL_REVIEW", this.reviewList);
    },
    pgFormatDate(date) {
      /* Via http://stackoverflow.com/questions/3605214/javascript-add-leading-zeroes-to-date */
      function zeroPad(d) {
        return ("0" + d).slice(-2);
      }

      function getDay(d) {
        var day = d.getDay();
        switch (day) {
          case 0:
            day = "Sunday";
            break;
          case 1:
            day = "Monday";
            break;
          case 2:
            day = "Tuesday";
            break;
          case 3:
            day = "Wednesday";
            break;
          case 4:
            day = "Thursday";
            break;
          case 5:
            day = "Friday";
            break;
          case 6:
            day = "Saturday";
            break;
          default:
            day = "undefined";
            break;
        }
        return day;
      }
      var parsed = new Date(date);
      var format =
        getDay(parsed) +
        " " +
        zeroPad(parsed.getDate()) +
        "." +
        zeroPad(parsed.getMonth() + 1) +
        "." +
        parsed.getUTCFullYear() +
        " at " +
        zeroPad(parsed.getHours()) +
        ":" +
        zeroPad(parsed.getMinutes()) +
        ":" +
        zeroPad(parsed.getSeconds());
      return format;
    },
    scrollToMax() {
      setTimeout(() => {
        let maxScroll = this.$refs.innerreview.offsetHeight;
        this.$refs.review.scrollTop = maxScroll;
      }, 100);
    }
  },
  created() {
    this.scrollToMax();
  }
};
</script>

<style scoped lang="scss">
#FeedbackResumeDetail {
  height: auto;
  position: relative;
  .stick {
    writing-mode: tb-rl;
    transform: rotate(180deg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ff0058;
    height: 25%;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.5px;
    color: #fff;
    padding: 5px;
    cursor: pointer;
  }
  .content {
    background: #fff;
    height: 100%;
    width: calc(500px + 1vw);
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid #151515;
    .cont-review {
      padding: 4vh 0.5vw 1vh 0.5vw;
      width: 100%;
      margin: 0 auto;
      .addReview {
        width: 100%;
        textarea {
          width: 100%;
        }
      }
      .review {
        position: relative;
        width: calc(100% - 1vw);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .inner-review {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 70%;
            margin: 0.5vh 0;
            border: 1px solid #ff0058;
            .txt-comment {
              padding: 1vh 0.5vw;
              text-align: left;
              width: 90%;
              word-break: break-word;
            }
            .comment-info {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              padding: 0 0.5vw 0.5vh 0.5vw;
              font-size: 0.7vw;
              font-style: italic;
              span {
                font-style: normal;
              }
            }
            &.MMD {
              background: #ff0058;
              color: #ffffff;
              align-self: flex-end;
              align-items: flex-end;
              .author {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              }
            }
            &.CLIENT {
              background: #ffffff;
              color: #151515;
              align-self: flex-start;
              align-items: flex-start;
              .author {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                p {
                  max-width: 50%;
                }
              }
            }
          }
        }
      }
    }
    .projects {
      width: 100%;
      margin-bottom: 4vh;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h4 {
        font-family: "lato_blackregular";
        letter-spacing: 0.4vw;
        font-size: 1.05vw;
        text-transform: uppercase;
        color: #151515;
      }
      textarea {
        border: 1px solid #151515;
        width: 95%;
        resize: vertical;
      }
    }
    .formats {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10vh;
      width: 100%;
      h4 {
        font-family: "lato_blackregular";
        letter-spacing: 0.4vw;
        font-size: 1.05vw;
        text-transform: uppercase;
        color: #151515;
      }
      .formatReview {
        width: 100%;
      }
    }
  }
  .button {
    position: absolute;
    width: calc(100% - 30px);
    bottom: 0;
    right: 0;
    height: 75px;
    border-top: 1px solid #151515;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #fff;
    .save {
      width: 30%;
      height: 30px;
      background: #ff0058;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      letter-spacing: 1.5px;
    }
  }
}
</style>

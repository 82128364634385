<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {},
  methods: {}
};
</script>

<style lang="scss">
@font-face {
  font-family: "lato_blackregular";
  src: url("./assets/fonts/lato-black-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato_blackitalic";
  src: url("./assets/fonts/lato-blackitalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latobold";
  src: url("./assets/fonts/lato-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latobold_italic";
  src: url("./assets/fonts/lato-bolditalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato_hairlineregular";
  src: url("./assets/fonts/lato-hairline-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-hairline-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato_hairlineitalic";
  src: url("./assets/fonts/lato-hairlineitalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-hairlineitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latoitalic";
  src: url("./assets/fonts/lato-italic-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato_lightregular";
  src: url("./assets/fonts/lato-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lato_lightitalic";
  src: url("./assets/fonts/lato-lightitalic-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "latoregular";
  src: url("./assets/fonts/lato-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
}
div,
p,
ul,
li {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "latoregular", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #151515;
  /* margin-top: 60px; */
  width: 100%;
  height: 100%;
}
body {
  /* background: linear-gradient(90deg, #d53369 0%, #daae51 100%); */
  background-color: #fff;
}

#nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  a {
    margin: 0px 10px;
  }
}
</style>

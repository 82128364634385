<template>
  <div class="head">
    <div class="right"></div>
    <div class="left">
      <div class="user" v-if="auth">
        <!-- <p>{{ $auth.profile.email }}</p> -->
        <a href="./docs/guide_plateforme_disney.pdf" target="_blank" class="userCreate">voir le guide d'utilisation</a>
        <div
          v-if="
            !this.$auth.profile['https://disneyViewer'] &&
              user.brand === 'MadMix Digital'
          "
          class="userCreate"
          @click="openCreateUserModal"
        >
          créer un user
        </div>
        <p @click="logout">logout</p>
        <img :src="$auth.profile.picture" alt />
      </div>
      <div class="user" v-else>not connected</div>
    </div>
  </div>
</template>

<script>
import Router from "@/router";

export default {
  name: "Header",
  methods: {
    logout() {
      console.log("logout");
      Router.app.$auth.logout();
    },
    openCreateUserModal() {
      this.$store.dispatch("TOGGLE_USER_CREATE_MODAL");
    }
  },
  props: {
    auth: Boolean,
    user: Object
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.head {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    .user {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px 20px;
      img {
        height: 100%;
        width: auto;
      }
      .userCreate {
        margin: 0 10px;
        cursor: pointer;
        color: black;
        text-decoration: none;

        &:hover {
          font-weight: bold;
        }
      }
      p {
        cursor: pointer;
        margin: 0 10px;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"projectBar"},[_c('div',{staticClass:"head"},[_c('h2',[_vm._v("Projets")]),(
        _vm.user.brand === 'MadMix Digital' && !this.$auth['https://disneyViewer']
      )?_c('div',{staticClass:"addProject",on:{"click":_vm.openModalNewProject}},[_vm._v(" + ")]):_vm._e()]),_c('div',{staticClass:"list"},[_vm._l((_vm.ProjectList),function(project,index){return _c('div',{key:index,staticClass:"item",class:[
        project.status ? 'valid' : 'pending',
        _vm.ActiveProject === index ? 'active' : null,
        'year' + parseInt(project.updatedAt.substring(0, 4))
      ],on:{"click":function($event){return _vm.click(index, project.id)}}},[_c('p',[_vm._v(" "+_vm._s(project.name)+" - "+_vm._s(project.status ? "Validé" : "En cours")+" ")])])}),_c('button',{staticClass:"load-more",attrs:{"hidden":!_vm.ProjectsOffset && _vm.ProjectsOffset !== 0},on:{"click":function($event){return _vm.getMoreProjects(_vm.ProjectsOffset)}}},[_vm._v("Charger les projets précédents")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="regieBar">
    <!-- <p>project actif : {{ id }}</p> -->
    <h2>Regies</h2>
    <UploadForm
      v-if="RegieList.length === 0 && user.brand === 'MadMix Digital'"
      :idProject="id"
      :upload="createProject"
      :text="'Upload your project zip'"
      :uploadType="'createProject'"
    />
    <div class="list">
      <div
        class="item"
        v-for="(regie, index) in RegieList"
        :key="index"
        :class="[
          regie.status ? 'valid' : 'pending',
          ActiveRegie === index ? 'active' : null
        ]"
        @click="click(index, regie.id)"
        v-on:dblclick="test(regie.id)"
      >
        <p>{{ regie.name }} - {{ regie.status ? "Validée" : "En cours" }}</p>
        <p v-if="!regie.status">
          {{ regie.Comment ? "Prochain rendu : " + regie.Comment : "" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import UploadForm from "../../../CoreComponent/components/forms/uploadForm/uploadForm.vue";

export default {
  name: "RegieBar",
  components: {
    UploadForm
  },
  props: {
    id: String,
    RegieList: Array,
    ActiveRegie: Number,
    click: Function,
    user: Object
  },
  methods: {
    createProject(formData) {
      this.$store.dispatch("UPLOAD_PROJECT_ZIP", formData);
    },
    updateProject(formData) {
      this.$store.dispatch("UPDATE_PROJECT_ZIP", formData);
    },
    test(id) {
      window.open(process.env.VUE_APP_URL + "/previewRegie/" + id);
    }
  }
};
</script>

<style lang="scss" scoped>
.regieBar {
  width: 60vw;
  border-right: 1px solid #151515;
  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  h2 {
    text-transform: uppercase;
  }
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    .item {
      width: 100%;
      // height: 50px;
      width: calc(100% - 1vw);
      font-size: 0.8vw;
      margin-top: 1vh;
      padding: 1vh 0.5vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 0 1.3vw 0 rgba(0, 0, 0, 0.3);
      cursor: pointer;
      position: relative;
      &:first-child {
        margin-top: 3vh;
        &::before {
          content: "En cours";
          /*border-top: 1px solid rgba(49, 49, 49, 0.71);*/
          width: 100%;
          height: auto;
          padding: 8px 0;
          line-height: 1;
          /*text-decoration: underline;*/
          text-align: left;
          font-size: 17px;
          position: absolute;
          top: -35px;
          left: 0;
          color: #151515;
        }
      }
      &.active {
        background-color: #151515;
        color: #ffffff;
      }
      &:hover {
        background-color: #151515;
        color: #ffffff;
      }
      &.valid {
        margin: 60px 0 0 0;
        border: 2px solid rgba(41, 167, 25, 0.6);

        &::before {
          content: "Validées";
          border-top: 1px solid rgba(49, 49, 49, 0.71);
          width: 100%;
          height: auto;
          padding: 8px 0;
          line-height: 1;
          /*text-decoration: underline;*/
          text-align: left;
          font-size: 17px;
          position: absolute;
          top: -35px;
          left: 0;
          color: #151515;
        }
        & ~ .valid {
          margin-top: 1vh;
          &::before {
            display: none;
          }
        }
        p {
          position: relative;
          opacity: 0.6;
          &::before {
            /*Add another block-level blank space*/
            content: "";
            display: block;
            position: absolute;
            /*Make it a small rectangle so the border will create an L-shape*/
            width: 3px;
            height: 6px;

            /*Add a white border on the bottom and left, creating that 'L' */
            border: solid rgb(27, 107, 16);
            border-width: 0 2px 2px 0;

            /*Rotate the L 45 degrees to turn it into a checkmark*/
            transform: translate(0, -50%) rotate(45deg);

            top: 50%;
            left: 0;
          }
        }
      }
      p {
        padding: 0.8vh 1vw 0.8vh 1vw;
      }
    }
  }
}
</style>

import Router from "../../router";
import Home from "./views/Home.vue";
import PreviewRegie from "./views/PreviewRegie.vue";
import PreviewFormat from "./views/PreviewFormat.vue";
import PublicPreview from "./views/PublicPreview.vue";
import PublicPreviewFormat from "./views/PublicPreviewFormat.vue";

Router.addRoutes([
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/previewRegie/:id",
    name: "previewRegie",
    component: PreviewRegie
  },
  {
    path: "/previewFormat/:id",
    name: "previewFormat",
    component: PreviewFormat
  },
  {
    path: "/publicPreview/:id",
    name: "publicPreview",
    component: PublicPreview
  },
  {
    path: "/publicPreviewFormat/:id",
    name: "publicPreviewFormat",
    component: PublicPreviewFormat
  }
]);

import Vue from "vue";
import Router from "vue-router";
import auth from "./lib/auth/auth";
import NotFound from "./modules/Errors/404.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/404",
      component: NotFound
    },
    {
      path: "*",
      redirect: "/404"
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (
    to.path === "/callback" ||
    to.path === "/login" ||
    to.path === "/retrieve-password" ||
    to.name === "publicPreview" ||
    to.name === "publicPreviewFormat" ||
    auth.isAuthenticated()
  ) {
    // auth.updateLocalStorage();
    return next();
  }

  // Specify the current path as the customState parameter, meaning it
  // will be returned to the application after auth
  // auth.login({ target: to.path });
  router.push({
    name: "login"
  });
});

export default router;

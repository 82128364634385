<template>
  <div class="loginApp">
    <LoginForm :auth="isAuthenticated" />
    <transition name="modal">
      <ResetPassword v-if="DisplayModalReset" />
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import LoginForm from "../components/LoginForm/LoginForm.vue";
import ResetPassword from "../../CoreComponent/components/Modals/ResetPassword.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
    ResetPassword
  },
  data() {
    return {
      isAuthenticated: false
    };
  },
  computed: {
    ...mapState({
      DisplayModalReset: state => state.Login.DisplayModalReset
    })
  },
  async created() {
    try {
      await this.$auth.renewTokens();
    } catch (e) {
      // console.log("catch");
      // console.log(e);
      // this.$auth.login({ target: this.$router.history.current.path });
    }
  },
  methods: {
    handleLoginEvent(data) {
      console.log("handleloginvue");
      this.isAuthenticated = data.loggedIn;
    }
  }
};
</script>
<style lang="scss">
.loginApp {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <form action @submit.prevent="submitUser" class="form-style-6">
    <div class="firstName">
      <label for="firstname">first name :</label>
      <input name="firstname" type="text" v-model="user.firstName" />
    </div>
    <div class="lastName">
      <label for="lastname">last name :</label>
      <input name="lastname" type="text" v-model="user.lastName" />
    </div>
    <div class="auth0">
      <label for="authid">id auth0 :</label>
      <input name="authid" type="text" v-model="user.authId" />
    </div>
    <div class="email">
      <label for="email">email :</label>
      <input name="email" type="text" v-model="user.mail" />
    </div>
    <div class="email">
      <label for="job">job :</label>
      <input name="job" type="text" v-model="user.job" />
    </div>
    <div class="brand">
      <label for="brand">brand :</label>
      <input name="brand" type="text" v-model="user.brand" />
    </div>
    <div class="mobile">
      <label for="mobilephone">mobilephone :</label>
      <input name="mobilephone" type="text" v-model="user.mobilePhone" />
    </div>
    <div class="office">
      <label for="officephone">officephone :</label>
      <input name="officephone" type="text" v-model="user.officePhone" />
    </div>
    <input type="submit" value="submit user" />
  </form>
</template>

<script>
export default {
  name: "createUserForm",
  props: {
    submit: Function,
    user: Object
  },
  methods: {
    submitUser() {
      this.submit();
    }
  }
};
</script>

<style lang="scss" scoped>
////////////// FORM //////////////////
.form-style-6 {
  // max-width: 400px;
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  h1 {
    background: #ff0058;
    padding: 20px 0;
    // font-size: 140%;
    font-weight: 300;
    text-align: center;
    color: #fff;
    margin: -16px -16px 16px -16px;
  }

  input[type="text"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="time"],
  input[type="url"],
  textarea,
  select,
  .v-select {
    // max-width: 400px;
    max-width: 80%;
    transition: all 0.3s ease-in-out;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    margin-bottom: 3vh;
    border: 1px solid #ccc;
    padding: 10px;
    color: #555;
    font: 95% Arial, Helvetica, sans-serif;
    &:focus {
      box-shadow: 0 0 5px #ff0058;
      padding: 12px;
      border: 1px solid #ff0058;
    }
  }
  input[type="submit"],
  input[type="button"] {
    width: 30%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /*width: 100%;*/
    padding: 12px;
    background: #ff0058;
    border-bottom: 2px solid #ff0058;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    color: #fff;
    margin: auto;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  input[type="submit"]:hover,
  input[type="button"]:hover {
    background: #ff0058;
  }
  // padding: 16px;
  // background: #f7f7f7;
}
</style>

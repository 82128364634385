<template>
  <div class="FormatBar">
    <!-- {{FormatList.Regie.Project}} -->
    <div class="contact" v-if="Project">
      <h2 @click="toggleContact" :class="displayContact ? 'actif' : null">
        <span>Réferents projet</span>
      </h2>
      <transition name="slide">
        <div class="contact-content" v-if="displayContact">
          <div class="mmdProd">
            <h3>MMD Contact</h3>
            <p v-if="Project.mmdProd.lastName && Project.mmdProd.firstName">
              {{ Project.mmdProd.lastName }}
              {{ Project.mmdProd.firstName }}
            </p>
            <p v-if="Project.mmdProd.job" class="list">
              {{ Project.mmdProd.job }}
            </p>
            <p v-if="Project.mmdProd.mail" class="list">
              {{ Project.mmdProd.mail }}
            </p>
            <p v-if="Project.mmdProd.mobilePhone" class="list">
              {{ Project.mmdProd.mobilePhone }}
            </p>
            <p v-if="Project.mmdProd.officePhone" class="list">
              {{ Project.mmdProd.officePhone }}
            </p>
          </div>
          <div v-if="Project.Users" class="disneyContact">
            <div
              class="disney"
              v-for="(cont, index) in Project.Users"
              :key="index"
            >
              <h3>DLP Contact n°{{ index + 1 }}</h3>
              <p v-if="cont.lastName && cont.firstName">
                {{ cont.lastName }}
                {{ cont.firstName }}
              </p>
              <p v-if="cont.job" class="list">{{ cont.job }}</p>
              <p v-if="cont.mail" class="list">{{ cont.mail }}</p>
              <p v-if="cont.mobilePhone" class="list">{{ cont.mobilePhone }}</p>
              <p v-if="cont.officePhone" class="list">{{ cont.officePhone }}</p>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="preview">
      <div v-if="Project" class="project">
        <h2>
          <span>Projet :</span>
          <br />
          {{ Project.name }}
        </h2>
        <p
          v-if="testDownloadProject()"
          @click="download(FormatList[0].Regie)"
          class="download"
        >
          Télécharger le projet
        </p>
        <p @click="openUpdateModal" class="download" v-if="test()">
          Mettre à jour le projet
        </p>
        <!-- <div class="up">
          <UploadForm
            v-if="RegieList.length > 0"
            :upload="updateProject"
            :idProject="Project.id"
            :text="'Update your project zip'"
            :uploadType="'updateProject'"
          />
        </div>-->
      </div>
      <div v-if="FormatList.length > 0" class="regie">
        <h2>
          <span>Regie :</span>
          <br />
          {{ FormatList[0].Regie.name }}
        </h2>
        <p>Nombre de formats : {{ FormatList.length }}</p>
        <p
          @click="downloadRegie(FormatList[0].Regie)"
          class="download"
          v-if="testDownloadRegie()"
        >
          Télécharger la Régie
        </p>
        <a :href="'/previewRegie/' + FormatList[0].Regie.id" target="_blank"
          >Lien de preview</a
        >
        <div class="Public">
          <h2>
            <span>Lien de preview regie :</span>
          </h2>
          <div class="form">
            <transition name="slide">
              <p
                v-if="copy"
                class="message"
                :class="type === 'error' ? 'error' : null"
              >
                {{ copy }}
              </p>
            </transition>
            <input
              id="linkPublic"
              type="text"
              :value="getUrl(FormatList[0].Regie.id)"
              readonly="readonly"
            />
          </div>
          <p @click.stop.prevent="copieLink()">Copier le lien</p>
        </div>
        <div
          class="dates"
          v-if="
            user.brand === 'MadMix Digital' &&
              !$auth.profile['https://disneyViewer'] &&
              !FormatList[0].Regie.status
          "
        >
          <h2>
            <span>Date de rendu :</span>
          </h2>

          <input
            type="text"
            name="date"
            id="dateRendu"
            v-model="FormatList[0].Regie.Comment"
          />
          <p @click="saveRenderDate">Sauvegarder la date de rendu</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import UploadForm from "../../../CoreComponent/components/forms/uploadForm/uploadForm.vue";

export default {
  name: "FormatBar",
  data() {
    return {
      displayContact: false,
      copy: "",
      type: ""
    };
  },
  props: {
    FormatList: Array,
    RegieList: Array,
    Project: Object,
    user: Object,
    ActiveRegie: Number
  },
  methods: {
    test() {
      if (
        !this.$auth.profile["https://disneyViewer"] &&
        this.user.brand === "MadMix Digital"
      ) {
        if (this.FormatList.length > 0) {
          if (!this.Project.status) {
            return true;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    copieLink() {
      var val = document.querySelector("#linkPublic");
      val.select();
      try {
        document.execCommand("copy");
        this.copy = "Le lien a bien été copié";
        setTimeout(() => {
          this.copy = "";
        }, 5000);
      } catch (err) {
        this.copy = "erreur de copie";
      }
    },
    saveRenderDate() {
      this.$store.dispatch("SAVE_RENDERDATE");
    },
    getUrl(id) {
      return process.env.VUE_APP_URL + "/publicPreview/" + id;
    },
    getImageUrl(url) {
      return url;
    },
    download(id) {
      let data = {
        Regie: id,
        user: this.user
      };

      this.$store.dispatch("DOWNLOAD_PROJECT", data);
    },
    downloadRegie(id) {
      let data = {
        Regie: id,
        user: this.user
      };
      this.$store.dispatch("DOWNLOAD_REGIE", data);
    },
    updateProject(formData) {
      this.$store.dispatch("UPDATE_PROJECT_ZIP", formData);
    },
    openUpdateModal() {
      this.$store.dispatch("TOGGLE_UPDATE_MODAL");
    },
    toggleContact() {
      this.displayContact = !this.displayContact;
    },
    testDownloadRegie() {
      let valid = true;
      if (this.FormatList.length > 0) {
        this.FormatList.map(item => {
          if (item.status === false) {
            valid = false;
          }
        });
      } else {
        valid = false;
      }
      return valid;
    },
    testDownloadProject() {
      let valid = true;
      if (this.RegieList.length > 0) {
        this.RegieList.map(item => {
          if (item.status === false) {
            valid = false;
          }
        });
      } else {
        valid = false;
      }
      return valid;
    }
  }
};
</script>

<style lang="scss" scoped>
.FormatBar {
  width: 20vw;
  border-right: 1px solid #151515;
  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  .contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    h2 {
      text-align: left;
      word-break: break-all;
      text-overflow: "-";
      width: 100%;
      line-height: 1.5;
      font-size: 18px;
      text-transform: uppercase;
      cursor: pointer;
      span {
        font-weight: normal;
        position: relative;
        font-size: 24px;
        &::after {
          content: " ";
          position: absolute;
          width: calc(100%);
          height: 1px;
          background: #151515;
          bottom: -3px;
          left: 0;
        }
      }

      &::after {
        content: "";
        border: 6px solid transparent;
        border-top: 6px solid #151515;
        margin-left: 5px;
        margin-bottom: 6px;
        display: inline-block;
        vertical-align: bottom;
        transform-origin: 50% 15%;
        transition: all 0.3s ease;
      }
      &.actif {
        &::after {
          transform: rotate(-180deg);
        }
      }
    }
    .contact-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .disney {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
  .preview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .project {
      width: 100%;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h2 {
        text-align: left;
        word-break: break-all;
        text-overflow: "-";
        width: 100%;
        line-height: 1.5;
        font-size: 18px;
        text-transform: uppercase;
        span {
          font-weight: normal;
          position: relative;
          font-size: 24px;
          &::after {
            content: " ";
            position: absolute;
            width: calc(100% - 10px);
            height: 1px;
            background: #151515;
            bottom: -3px;
            left: 0;
          }
        }
      }
      .download {
        margin-bottom: 15px;
        margin-top: 10px;
        #main {
          .upload {
            .cont-upload-box {
              .cont-left-upload {
                .dropbox {
                  height: 150px;
                }
              }
            }
          }
        }
      }
      button,
      .download {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 100%;
        padding: 12px;
        background: #ff0058;
        border-bottom: 2px solid #ff0058;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        color: #fff;
        max-width: 200px;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
      a {
        text-decoration: underline;
        color: #2c3e50;
        &:hover {
          font-weight: bold;
        }
      }
    }
    .regie {
      width: 100%;
      .Public {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 50px;
        > p,
        h2 {
          &:first-child {
            text-align: left;
            word-break: break-all;
            text-overflow: "-";
            width: 100%;
            line-height: 1.5;
            font-size: 18px;
            text-transform: uppercase;
            span {
              font-weight: normal;
              position: relative;
              font-size: 24px;
              &::after {
                content: " ";
                position: absolute;
                width: calc(100% - 10px);
                height: 1px;
                background: #151515;
                bottom: -3px;
                left: 0;
              }
            }
          }
          &:not(:first-child):not(.message) {
            width: 100%;
            background-color: #ff0058;
            color: #ffffff;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            height: 50px;
            &:hover {
              font-weight: bold;
            }
          }
        }
        .form {
          width: 100%;
          p {
            color: #108d10;
            font-size: 15px;
            &.error {
              color: #be1313;
            }
          }
          input {
            width: 100%;
            text-align: left;
          }
        }
      }
      .dates {
        width: 100%;
        input {
          width: 100%;
        }
        p {
          width: 100%;
          background-color: #ff0058;
          color: #ffffff;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 50px;
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
}
.slide-enter-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.slide-leave-active {
  -moz-transition-duration: 1s;
  -webkit-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to,
.slide-leave {
  max-height: 100vh;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
input[type="text"] {
  // max-width: 400px;
  // max-width: 80%;
  transition: all 0.3s ease-in-out;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  margin-bottom: 1vh;
  border: 1px solid #ccc;
  padding: 10px;
  color: #555;
  font: 95% Arial, Helvetica, sans-serif;
}

input[type="text"]:focus {
  box-shadow: 0 0 5px #ff0058;
  padding: 12px;
  border: 1px solid #ff0058;
}
</style>

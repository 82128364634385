<template>
  <div id="publicPreview">
    <!-- <p>salut</p> -->
    <PreviewPublicContent />
  </div>
</template>

<script>
import PreviewPublicContent from "../components/Preview/PreviewPublicContent.vue";

export default {
  name: "PublicPreview",
  components: { PreviewPublicContent },
  async created() {
    try {
      this.$store.dispatch(
        "LOAD_PREVIEW_DATA_PUBLIC",
        window.location.pathname.split("/").pop()
      );
    } catch (e) {
      console.log(e);
    }
  }
};
</script>

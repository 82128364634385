<template>
  <div class="modal-mask">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" v-on-clickaway="away">
          <div class="head">
            <p class="close" @click="close">X</p>
            <p class="title">Are you sure to delete this format ?</p>
          </div>
          <div class="buttons">
            <div class="button" @click="deleteFormat">Delete format</div>
            <div class="button cancel" @click="close">Cancel</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mixin as clickaway } from "vue-clickaway";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";

Vue.component("v-select", vSelect);
export default {
  mixins: [clickaway],
  data() {
    return {
      Pname: "",
      mmdUserMail: [],
      selectMMD: "",
      brandNameList: [],
      selectBrand: "",
      disneyUsersMail: [],
      selectDisney: [],
      globalComment: "",
      interlocuteur: ""
    };
  },
  name: "DeleteFormat",
  computed: {
    ...mapState({
      FormatToDelete: state => state.Home.FormatToDelete
    })
  },
  props: [],
  methods: {
    away() {
      this.$store.dispatch("TOGGLE_DELETE_FORMAT_MODAL");
    },
    close() {
      this.$store.dispatch("TOGGLE_DELETE_FORMAT_MODAL");
    },
    deleteFormat() {
      this.$store.dispatch("DELETE_ONE_FORMAT", this.FormatToDelete);
    }
  },
  created: function() {
    this.$store.dispatch("GET_USERS_DISNEY_AND_MMD");
  },
  watch: {
    MmdUsers(newValue) {
      this.mmdUserMail = [];
      newValue.map(user => {
        this.mmdUserMail.push(user.mail);
      });
    },
    DisneyUsers(newValue) {
      this.disneyUsersMail = [];
      newValue.map(user => {
        this.disneyUsersMail.push(user.mail);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  height: 15vh;
  max-width: 30%;
  margin: 0px auto;
  padding: 2vh;
  background-color: #fff;
  overflow: hidden;
  // border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  .head {
    margin-bottom: 2vh;
    .title {
      text-transform: uppercase;
      font-family: "latobold";
      font-size: 1.2vw;
      text-align: left;
    }
  }
  .content {
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .sub-title {
      text-transform: uppercase;
      font-family: "latobold";
      font-size: 1vw;
      text-align: left;
      margin-bottom: 2vh;
    }
    label {
      text-transform: uppercase;
    }
    .right,
    .left {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .left {
      position: relative;
      // &:after {
      //   content: "";
      //   width: 1px;
      //   height: 100%;
      //   background-color: #151515;
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      // }
      textarea {
        width: 90%;
      }
    }
    .right {
      padding-left: 2vh;
      overflow: auto;
      .head {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .plusCrea {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 15px;
          height: 15px;
          border: 1px solid #151515;
          cursor: pointer;
          &:hover {
            background: #151515;
            color: #ff0058;
          }
        }
      }
      .contentRight {
        width: 100%;
      }
    }
  }
  .buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .button {
    // float: right;
    display: flex;
    flex-direction: row;
    width: 200px;
    // align-self: flex-end;
    // justify-self: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #151515;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    /*width: 100%;*/
    padding: 12px;
    background: #ff0058;
    border-bottom: 2px solid #ff0058;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    color: #fff;
    margin: auto;
    cursor: pointer;
    // margin-right: 10px;
    &:hover {
      font-weight: bold;
    }
    /*&:hover {
      background-color: #151515;
      color: #ff0058;
    }*/

    &.cancel{
      background: transparent;
      border: 1px solid #ff0058;
      color: #ff0058;

    }
  }
}
.close {
  position: absolute;
  right: 30px;
  font-weight: bold;
  cursor: pointer;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}
////////////// FORM //////////////////
.form-style-6 {
  // max-width: 400px;
  width: auto;
  margin: 0 auto;
  // padding: 16px;
  // background: #f7f7f7;
}
.form-style-6 h1 {
  background: #ff0058;
  padding: 20px 0;
  // font-size: 140%;
  font-weight: 300;
  text-align: center;
  color: #fff;
  margin: -16px -16px 16px -16px;
}
.form-style-6 input[type="text"],
.form-style-6 input[type="date"],
.form-style-6 input[type="datetime"],
.form-style-6 input[type="email"],
.form-style-6 input[type="number"],
.form-style-6 input[type="search"],
.form-style-6 input[type="time"],
.form-style-6 input[type="url"],
.form-style-6 textarea,
.form-style-6 select,
.form-style-6 .v-select {
  // max-width: 400px;
  max-width: 80%;
  transition: all 0.3s ease-in-out;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  margin-bottom: 3vh;
  border: 1px solid #ccc;
  padding: 10px;
  color: #555;
  font: 95% Arial, Helvetica, sans-serif;
}
.form-style-6 .v-select {
  padding: 0;
  border: none;
  cursor: pointer !important;
  border-radius: 0 !important;
}
.vs--searchable .vs__dropdown-toggle {
  border-radius: 0 !important;
}
.vs__actions {
  cursor: pointer !important;
}
.form-style-6 input[type="text"]:focus,
.form-style-6 input[type="date"]:focus,
.form-style-6 input[type="datetime"]:focus,
.form-style-6 input[type="email"]:focus,
.form-style-6 input[type="number"]:focus,
.form-style-6 input[type="search"]:focus,
.form-style-6 input[type="time"]:focus,
.form-style-6 input[type="url"]:focus,
.form-style-6 textarea:focus,
.form-style-6 select:focus,
.form-style-6 .v-select:focus {
  box-shadow: 0 0 5px #ff0058;
  padding: 12px;
  border: 1px solid #ff0058;
}
.form-style-6 input[type="submit"],
.form-style-6 input[type="button"] {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  padding: 12px;
  background: #ff0058;
  border-bottom: 2px solid #ff0058;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  color: #fff;
}
.form-style-6 input[type="submit"]:hover,
.form-style-6 input[type="button"]:hover {
  background: #ff0058;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
<style lang="scss">
// .v-select {
//   width: 90%;
//   .vs--searchable .vs__dropdown-toggle {
//     min-width: 100% !important;
//   }
// }
// .vs--searchable .vs__dropdown-toggle {
//   width: 100% !important;
// }
</style>

<template>
  <div
      class="formatReview"
      v-if="
      previewData.Formats[this.index].formats[this.index2] &&
        ActiveUser.firstName
    "
  >
    <div class="head">
      <h5>
        {{ previewData.Formats[index].formats[index2].size }}
        {{ previewData.Formats[index].formats[index2].note ? "-" : null }}
        {{ previewData.Formats[index].formats[index2].note }}
      </h5>
      <!--        ici-->
      <div class="cont-checkbox" v-if="testLastCheckBox()">
        <label>valider</label>
        <input
            type="checkbox"
            class="checkbox"
            v-model="previewData.Formats[index].formats[index2].status"
            @change="resetComment($event, previewData.Formats[index].formats[index2].status)"
        />
      </div>
    </div>
    <div
        class="cont-text-area"
        v-if="!previewData.Formats[index].formats[index2].status"
    >
      <!--        ici-->
      <textarea cols="30" rows="5" v-model="txt" v-if="testLast()"></textarea>
      <div
          class="cont-sub-send"
          v-if="ActiveUser.brand === 'MadMix Digital' && txt"
      >
        <p class="send-open" @click="sendCustomFeedback">send this feedback</p>
      </div>
      <div
          class="cont-sub-send delete"
          v-if="ActiveUser.brand === 'MadMix Digital'"
      >
        <p class="send-open" @click="deleteCustomFormat">delete this format</p>
      </div>
      <div class="cont-sub-feedback">
        <div class="cont-comment">
          <p
              class="link-open"
              v-if="
              previewData.Formats[index].formats[index2].FormatEvenements
                .length > 0
            "
              @click="deploy"
              ref="linkopen"
          >
            Voir le dernier commentaire
          </p>
          <p class="link-open" v-else>Pas encore de commentaire</p>
          <div class="seeMore" v-if="seeMore">
            <p
                class="txt-comment"
                v-if="
                seeMore &&
                  previewData.Formats[index].formats[index2].FormatEvenements[
                    previewData.Formats[index].formats[index2].FormatEvenements
                      .length - 1
                  ].text
              "
            >
              {{
                previewData.Formats[index].formats[index2].FormatEvenements[
                previewData.Formats[index].formats[index2].FormatEvenements
                    .length - 1
                    ].text
              }}
            </p>
            <p
                class="txt-comment"
                v-if="
                seeMore &&
                  !previewData.Formats[index].formats[index2].FormatEvenements[
                    previewData.Formats[index].formats[index2].FormatEvenements
                      .length - 1
                  ].text
              "
            >
              Commentaire Vide
            </p>
            <div class="comment-info">
              <p class="user" v-if="seeMore">
                by
                <span>
                  {{
                    previewData.Formats[index].formats[index2].FormatEvenements[
                    previewData.Formats[index].formats[index2]
                        .FormatEvenements.length - 1
                        ].User.firstName
                  }}
                </span>
              </p>
              <p class="time" v-if="seeMore">
                on
                {{
                  pgFormatDate(
                      previewData.Formats[index].formats[index2].FormatEvenements[
                      previewData.Formats[index].formats[index2]
                          .FormatEvenements.length - 1
                          ].createdAt
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button">
      <router-link
          :to="{
          name: 'previewFormat',
          params: { id: previewData.Formats[index].formats[index2].id }
        }"
          target="_blank"
      >Voir les retours du format
      </router-link
      >
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FormatReview",
  props: {
    index: Number,
    index2: Number
  },
  data() {
    return {
      txt: "",
      seeMore: false,
      auth: false
    };
  },
  components: {},
  computed: {
    ...mapState({
      previewData: state => state.Home.PreviewRegie,
      ActiveUser: state => state.Home.ActiveUser
    })
  },
  methods: {
    testLastCheckBox() {
      if (this.ActiveUser.brand === "Disney") {
        // console.log("TLCB this.ActiveUser.brand " + this.ActiveUser.brand);
        if (
            this.previewData.Formats[this.index].formats[this.index2]
                .FormatEvenements.length > 0
        ) {
          // console.log("TLCB this.previewData.Formats[this.index].formats[this.index2].FormatEvenements.length > 0");
          if (
              this.previewData.Formats[this.index].formats[this.index2]
                  .FormatEvenements[
              this.previewData.Formats[this.index].formats[this.index2]
                  .FormatEvenements.length - 1
                  ].User.brand === "Disney"
          ) {

            // console.log("TLCB Le dernier commentaire du format a été fait par Disney - donc je désactive la possibilité de commenter")
            // console.log("ici", 3);
            // return true;

            return false;
          } else {
            // console.log("TLCB Le dernier commentaire du format a été fait par MMD - donc j'ouvre la possibilité de commenter")
            // console.log("ici", 4);
            return true;
          }
        } else {
          // console.log("TLCB Il n'y a pas d'évènement attaché au format")
          // console.log("ici", 5);
          return true;
        }
      } else {
        if (!this.$auth.profile["https://disneyViewer"]) {
          // console.log("TLCB L'user n'a pas le role DisneyViewer")
          // console.log("ici", 6);
          return false;
        }
      }
    },
    testLast() {
      if (this.$auth.profile["https://disneyViewer"]) {
        // console.log("TL L'user a le role DisneyViewer")
        return false;
      } else {
        // console.log("TL L'user n'a pas le role DisneyViewer")

        if (this.ActiveUser.brand === "MadMix Digital") {
          // console.log("TL this.ActiveUser.brand === MadMix Digital" )
          return true;

        } else {
          // console.log("TL this.ActiveUser.brand !== MadMix Digital" )

          if (
              this.previewData.Formats[this.index].formats[this.index2]
                  .FormatEvenements.length > 0
          ) {
            // console.log("TL this.previewData.Formats[this.index].formats[this.index2].FormatEvenements.length > 0")

            if (
                this.previewData.Formats[this.index].formats[this.index2]
                    .FormatEvenements[
                this.previewData.Formats[this.index].formats[this.index2]
                    .FormatEvenements.length - 1
                    ].User.brand === "Disney"
            ) {
              // console.log("TL Le dernier commentaire du format a été fait par Disney - donc je désactive la possibilité de commenter")

              return false;
            } else {
              // console.log("TL Le dernier commentaire du format a été fait par MMD - donc j'ouvre la possibilité de commenter")

              return true;
            }
          } else {
            if (!this.$auth.profile["https://disneyViewer"]) {
              // console.log("TL L'user n'a pas le role DisneyViewer")
              return true;
            }
          }
        }
      }
    },
    deploy() {
      this.seeMore = !this.seeMore;
      if (this.seeMore === true) {
        this.$refs.linkopen.innerHTML = "Masquer le dernier commentaire";
      } else {
        this.$refs.linkopen.innerHTML = "Voir le dernier commentaire";
      }
    },
    resetComment(e, checkboxValue) {
      if (checkboxValue) {
        this.txt = "Format Validé";
      } else {
        this.txt = "";
      }
    },
    pgFormatDate(date) {
      /* Via http://stackoverflow.com/questions/3605214/javascript-add-leading-zeroes-to-date */
      function zeroPad(d) {
        return ("0" + d).slice(-2);
      }

      function getDay(d) {
        var day = d.getDay();
        switch (day) {
          case 0:
            day = "Sunday";
            break;
          case 1:
            day = "Monday";
            break;
          case 2:
            day = "Tuesday";
            break;
          case 3:
            day = "Wednesday";
            break;
          case 4:
            day = "Thursday";
            break;
          case 5:
            day = "Friday";
            break;
          case 6:
            day = "Saturday";
            break;
          default:
            day = "undefined";
            break;
        }
        return day;
      }

      var parsed = new Date(date);
      var format =
          getDay(parsed) +
          " " +
          zeroPad(parsed.getDate()) +
          "." +
          zeroPad(parsed.getMonth() + 1) +
          "." +
          parsed.getUTCFullYear() +
          " at " +
          zeroPad(parsed.getHours()) +
          ":" +
          zeroPad(parsed.getMinutes()) +
          ":" +
          zeroPad(parsed.getSeconds());
      return format;
    },
    sendCustomFeedback() {
      console.log(this.txt);
      // console.log();
      this.$store.dispatch("SAVE_ONE_REVIEW", {
        id: this.previewData.Formats[this.index].formats[this.index2].id,
        msg: this.txt,
        reviewRegie: this.previewData.Project.id,
        usr: this.ActiveUser.id,
        email: this.$auth.profile.email
      });
    },
    deleteCustomFormat() {
      // console.log({
      //   id: this.previewData.Formats[this.index].formats[this.index2].id,
      //   usr: this.ActiveUser.id,
      //   email: this.$auth.profile.email
      // });
      var data = {
        id: this.previewData.Formats[this.index].formats[this.index2].id,
        usr: this.ActiveUser.id,
        email: this.$auth.profile.email
      };
      this.$store.dispatch("TOGGLE_DELETE_FORMAT_MODAL", data)
    }
  },
  created() {
    if (this.$auth.isAuthenticated) {
      this.auth = true;
    } else {
      this.auth = false;
    }
  },
  watch: {
    txt(val) {
      let data = {
        text: val,
        author: this.ActiveUser.id,
        formatId: this.previewData.Formats[this.index].formats[this.index2].id
      };
      if (data.txt === "Format Validé") {
        data.type = "Validation";
      } else {
        data.type = "Feedback";
      }
      this.$store.dispatch("CONFIRM_REVIEW", data);
    }
  }
};
</script>

<style scoped lang="scss">
.formatReview {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;

  &:first-child {
    margin-top: 0;
  }

  > h5 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
    position: relative;
    flex: 1;
    flex-grow: 1;
    width: fit-content;
    z-index: 2;

    &::after {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 0;
      width: calc(100% + 28px);
      right: -29px;
      height: 3px;
      background-color: #ff0058;
    }

    &::before {
      z-index: -1;
      position: absolute;
      content: "";
      bottom: 0;
      left: calc(100% + 28px);
      height: 0;
      width: 0;
      border-right: 12px solid transparent;
      border-bottom: 10px solid #ff0058;
    }
  }

  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.wrong {
      color: red;
    }

    .cont-checkbox {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-right: 5%;

      input {
        margin: 0 0 0 0.5vw;
        background-color: #ffffff;
        border: 1px solid #151515;
      }
    }
  }

  .button {
    position: relative;
    width: calc(100% - 30px);
    // border-top: 1px solid #151515;
    display: flex;
    flex-direction: row;
    align-items: right;
    justify-content: right;
    background: #fff;

    a {
      margin: 1vh 0 0 0;
      padding: 0.5vh 1vw;
      font-weight: 300;
      background-color: #ff0058;
      border: 1px solid #ff0058;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      letter-spacing: 1.5px;

      &:hover {
        background-color: #ffffff;
        color: #ff0058;
      }
    }
  }

  .cont-text-area {
    display: flex;
    flex-direction: column;
    width: 95%;

    textarea {
      background-color: #ffffff;
      border: 1px solid #151515;
      width: calc(100% - 0.4vw);
      padding: 0.2vh 0.2vw;
      resize: vertical;

      &.wrong {
        border: 1px solid red;
      }
    }

    .cont-sub-send {
      width: 100%;
      background-color: white;
      border: 1px solid #151515;
      color: #151515;

      .send-open {
        padding: 0.5vh 0;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: lightgreen;
      }

      &.delete {
        &:hover {
          background-color: rgb(255, 78, 78);

          p {
            color: #fff;
          }
        }
      }
    }

    .cont-sub-feedback {
      background-color: #151515;
      border: 1px solid #151515;
      color: #ffffff;
      font-weight: 300;
      width: 100%;

      .link-open {
        padding: 0.5vh 0;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .cont-comment {
        .seeMore {
          background-color: #ff0058;
          color: #ffffff;

          .txt-comment {
            padding: 1vh 0.5vw;
            text-align: left;
            width: 90%;
            word-break: break-word;
          }

          .comment-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 0 0.5vw 0.5vh 0.5vw;
            font-size: 0.7vw;
            font-style: italic;

            span {
              font-style: normal;
              white-space: pre;
            }

            .user {
              span {
                font-style: normal;
                white-space: pre;
              }
            }
          }
        }
      }
    }
  }
}
</style>

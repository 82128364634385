var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.previewData && _vm.previewData.name)?_c('div',{attrs:{"id":"PreviewContent"}},[_c('div',{staticClass:"head"},[_c('h3',[_vm._v(_vm._s(_vm.previewData.Project.name))]),_c('h4',[_vm._v(_vm._s(_vm.previewData.name))])]),_c('div',{staticClass:"content"},_vm._l((_vm.previewData.Formats),function(spec,index){return _c('div',{key:index,staticClass:"spec"},[_c('div',{staticClass:"spec"},[(spec.spec !== 'noSpec')?_c('h5',{on:{"click":function($event){return _vm.toggleSpec(index)}}},[_vm._v(" "+_vm._s(spec.spec)+" ")]):_vm._e(),_c('div',{staticClass:"formats",class:spec.deployPreview ? 'deploy' : null},_vm._l((spec.formats),function(format,index2){return _c('div',{key:index2,staticClass:"format"},[_c('img',{class:format.status ? 'valid' : null,style:({
                width: format.size.split('x')[0] + 'px',
                height: format.size.split('x')[1].split('-')[0] + 'px'
              }),attrs:{"src":_vm.getImgUrl(format.url)},on:{"click":function($event){return _vm.activeReview(format.id)}}}),_c('div',{staticClass:"txt"},[_c('p',[_vm._v(" Detail preview : "),_c('router-link',{attrs:{"to":{
                    name: 'previewFormat',
                    params: { id: format.id }
                  },"target":"_blank"}},[_vm._v(" "+_vm._s(format.size)+" "+_vm._s(format.url ? "-" : null)+" "+_vm._s(format.url.split("/")[format.url.split("/").length - 1])+" ")]),_c('span',{staticStyle:{"color":"green","font-size":"14px"}},[_vm._v(" "+_vm._s(format.status ? "Format validé" : null)+" ")])],1)])])}),0)])])}),0),_c('FeedbackResume',{staticClass:"FeedbackComp",class:_vm.deploy ? 'active' : null,attrs:{"toggleFeedback":_vm.toggleFeedbackBar,"previewData":_vm.previewData,"active":_vm.active}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
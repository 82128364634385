import Vue from "vue";
import Vuex from "vuex";
import Home from "./modules/Home/stores/index";
import Login from "./modules/Login/stores/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Home,
    Login
  }
});

<template>
  <div id="PreviewContent" v-if="previewData && previewData.name">
    <!-- salut2 -->
    <div class="head">
      <h3>{{ previewData.Project.name }}</h3>
      <h4>{{ previewData.name }}</h4>
    </div>
    <div class="content">
      <!-- {{previewData.Formats}} -->
      <div
        class="spec"
        v-for="(spec, index) in previewData.Formats"
        :key="index"
      >
        <div class="spec">
          <h5 @click="toggleSpec(index)" v-if="spec.spec !== 'noSpec'">
            {{ spec.spec }}
          </h5>
          <div class="formats" :class="spec.deployPreview ? 'deploy' : null">
            <div
              class="format"
              v-for="(format, index2) in spec.formats"
              :key="index2"
            >
              <img
                :src="getImgUrl(format.url)"
                :style="{
                  width: format.size.split('x')[0] + 'px',
                  height: format.size.split('x')[1].split('-')[0] + 'px'
                }"
              />

              <div class="txt">
                <p>
                  Detail preview :
                  <router-link
                    :to="{
                      name: 'publicPreviewFormat',
                      params: { id: format.id }
                    }"
                    target="_blank"
                  >
                    {{ format.size }} {{ format.url ? "-" : null }}
                    {{
                      format.url.split("/")[format.url.split("/").length - 1]
                    }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PreviewPublicContent",
  props: {},
  computed: {
    ...mapState({
      previewData: state => state.Home.PreviewPublicRegie
    })
  },
  data() {
    return {
      deploy: false,
      active: 0
    };
  },
  methods: {
    toggleFeedbackBar() {
      this.deploy = !this.deploy;
    },
    getImgUrl(url) {
      return url + "?test=" + Date.now();
    },
    toggleSpec(id) {
      this.$store.dispatch("TOGGLE_PREVIEWDATA_TOGGLE", id);
    }
  },
  watch: {
    previewData: function(val) {
      document.title = val.Project.name + " | " + val.name;
    }
  }
};
</script>

<style scoped lang="scss">
#PreviewContent {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  .head {
    position: relative;
    width: 80vw;
    height: auto;
    margin: 4vh auto 4vh auto;
    h3 {
      width: 100%;
      text-align: initial;
      font-family: "lato_blackregular";
      font-size: 5vw;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      color: #151515;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      letter-spacing: 1.5vw;
      opacity: 0.2;
      margin-top: 1vh;
      color: #ffffff;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #151515;
    }
    h4 {
      font-size: 3vw;
      letter-spacing: 0.8vw;
      display: flex;
      line-height: 3vw;
      color: #151515;
      font-family: "latoregular";
      font-weight: 900;
      position: absolute;
      bottom: 10px;
      left: 0;
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
      margin: 0;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 80vw;
    height: auto;
    margin: auto;
    .spec {
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h5 {
        text-align: left;
        font-size: 16px;
        position: relative;
        flex: 1;
        &::after {
          z-index: -1;
          position: absolute;
          content: "";
          bottom: 0;
          width: calc(100% + 28px);
          right: -29px;
          height: 3px;
          background-color: #ff0058;
        }
        &::before {
          z-index: -1;
          position: absolute;
          content: "";
          bottom: 0;
          left: calc(100% + 28px);
          height: 0;
          width: 0;
          border-right: 12px solid transparent;
          border-bottom: 10px solid #ff0058;
        }
      }
      .formats {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        // transform: scaleY(0);
        height: auto;
        transform-origin: top center;
        // max-height: 0;
        transition: all 0.3s ease-in-out;
        // overflow: hidden;

        &.deploy {
          // max-height: 100%;
          // transform: scaleY(1);
        }
        .format {
          display: flex;
          flex-direction: column;
          // justify-content: flex-start;
          align-items: flex-start;
          margin: 10px 10px 25px 0;

          /*max-width: 600px;*/
          &::after {
            content: " ";
            width: 50vw;
            height: 1px;
            background: rgba(49, 49, 49, 0.71);
            margin-top: 25px;
          }
        }
      }
      img {
        border: 1px solid #151515;
        &.valid {
          display: none;
        }
      }
      p {
        padding: 0.5vh 0 0 0;
        a {
          color: #151515;
          &:hover {
            color: #ff0058;
          }
        }
        span {
          white-space: pre;
        }
      }
    }
  }
  .FeedbackComp {
    position: fixed;
    height: 100vh;
    width: auto;
    background: transparent;
    right: calc(-500px - 1vw);
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s;
    &.active {
      right: 0;
    }
  }
}
</style>

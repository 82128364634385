<template>
  <div class="log">
    <img :src="require('../../../../assets/disney/logo.png')" alt />
    <form @submit.prevent="login" v-if="!auth" class="form-style-6">
      <p v-if="loginError" class="errors">{{ loginError }}</p>
      <div class="email">
        <input name="email" type="text" v-model="email" placeholder="email" />
      </div>
      <div class="pwd">
        <input
          name="password"
          type="password"
          v-model="password"
          placeholder="password"
        />
      </div>
      <input type="submit" value="login" />
      <p class="changepassword" @click="displayResetModal">Change password</p>
      <p class="errors mail" v-if="errors.email">{{ errors.email }}</p>
      <p class="errors pwd" v-if="errors.password">{{ errors.password }}</p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Router from "@/router";

export default {
  name: "LoginForm",
  props: {
    auth: Boolean
  },
  data() {
    return {
      isAuthenticated: false,
      email: "",
      password: "",
      errors: {
        email: "",
        password: ""
      },
      emailRescue: ""
    };
  },
  computed: {
    ...mapState({
      loginError: state => state.Login.loginError
    })
  },
  methods: {
    login() {
      console.log("login");

      if (this.checkForm(this.email, this.password)) {
        this.errors.email = "";
        this.errors.password = "";
        this.$store.dispatch("LOGIN", {
          email: this.email,
          password: this.password
        });
        // Router.app.$auth.login({ email: this.email, password: this.password });
      }
    },
    logout() {
      console.log("logout");
      this.errors.email = "";
      this.errors.password = "";
      Router.app.$auth.logout();
    },
    displayResetModal() {
      Router.push({ name: "Password" });
    },
    checkForm(mail, pwd) {
      console.log("check");

      let check = true;
      /* eslint-disable */
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(mail)) {
        this.errors.email = "your email is not a valid email please check it";
        check = false;
      }
      if (!pwd) {
        this.errors.password =
          "Your password field is empty please do not forget it";
        check = false;
      }
      return check;
      /* eslint-enable */
    }
  },
  created() {
    this.errors.email = "";
    this.errors.password = "";
  }
};
</script>

<style scoped lang="scss">
.log {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 400px;
  align-items: center;
  img {
    width: 100%;
    max-width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  form {
    display: flex;
    padding: 50px 0;
    box-shadow: #151515d6 0px 0px 3px 0px;
    .errors {
      color: #ea1a1a;
      font-size: 12px;
      margin-bottom: 5px;
    }
    .changepassword {
      width: 80%;
      margin-top: 10px;
      text-align: right;
      cursor: pointer;
      &:hover {
        font-weight: bold;
      }
    }
  }
}
////////////// FORM //////////////////
.form-style-6 {
  // max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    background: #ff0058;
    padding: 20px 0;
    // font-size: 140%;
    font-weight: 300;
    text-align: center;
    color: #fff;
    margin: -16px -16px 16px -16px;
  }
  > div {
    width: 100%;
  }
  input[type="text"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="time"],
  input[type="url"],
  input[type="password"],
  textarea,
  select,
  .v-select {
    // max-width: 400px;
    max-width: 80%;
    transition: all 0.3s ease-in-out;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    margin-bottom: 3vh;
    border: 1px solid #ccc;
    padding: 10px;
    color: #555;
    font: 95% Arial, Helvetica, sans-serif;
    &:focus {
      box-shadow: 0 0 5px #ff0058;
      padding: 12px;
      border: 1px solid #ff0058;
    }
  }
  input[type="submit"],
  input[type="button"] {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 80%;
    padding: 12px;
    background: #ff0058;
    border-bottom: 2px solid #ff0058;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    color: #fff;
    cursor: pointer;
  }
  input[type="submit"]:hover,
  input[type="button"]:hover {
    background: #ff0058;
  }
  // padding: 16px;
  // background: #f7f7f7;
}
</style>

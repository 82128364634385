<template>
  <div class="projectBar">
    <div class="head">
      <h2>Projets</h2>
      <div
        v-if="
          user.brand === 'MadMix Digital' && !this.$auth['https://disneyViewer']
        "
        class="addProject"
        @click="openModalNewProject"
      >
        +
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(project, index) in ProjectList"
        :class="[
          project.status ? 'valid' : 'pending',
          ActiveProject === index ? 'active' : null,
          'year' + parseInt(project.updatedAt.substring(0, 4))
        ]"
        :key="index"
        @click="click(index, project.id)"
      >
        <p>
          {{ project.name }} -
          {{ project.status ? "Validé" : "En cours" }}
        </p>
      </div>
      <button @click="getMoreProjects(ProjectsOffset)" class="load-more" :hidden="!ProjectsOffset && ProjectsOffset !== 0">Charger les projets précédents</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectBar",
  data() {
    return {
      verif: false
    };
  },
  props: {
    ProjectList: Array,
    click: Function,
    ActiveProject: Number,
    user: Object,
    ProjectsOffset : [Number, Boolean],
  },
  methods: {
    openModalNewProject() {
      this.$store.dispatch("TOGGLE_MODAL_NEW_PROJECT");
    },
    testActive(status) {
      if (!status && !this.verif) {
        this.verif = true;
        return true;
      } else {
        return false;
      }
    },
    getMoreProjects(offset) {
      // console.log(offset)
      this.$store.dispatch("GET_MORE_PROJECT_LIST", {offset : offset});
    }
  }
};
</script>

<style lang="scss" scoped>
.projectBar {
  width: 20vw;
  border-right: 1px solid #151515;
  margin: 0;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  .head {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    h2 {
      text-transform: uppercase;
    }
    .addProject {
      width: 20px;
      height: 20px;
      border: 1px solid #151515;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        background: #151515;
        color: #ff0058;
      }
    }
  }
  .list {
    margin-top: 0;
    width: 100%;
    &:first-child {
      margin-top: 2vh;
    }
    .item {
      width: 100%;
      // height: 50px;
      width: calc(100% - 1vw);
      font-size: 0.8vw;
      margin-top: 1vh;

      padding: 1vh 0.5vw;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      box-shadow: 0 0 1.3vw 0 rgba(0, 0, 0, 0.3);
      position: relative;
      cursor: pointer;
      &:first-child {
        margin-top: 3vh;
        &::before {
          content: "En cours";
          /*border-top: 1px solid rgba(49, 49, 49, 0.71);*/
          width: 100%;
          height: auto;
          padding: 8px 0;
          line-height: 1;
          /*text-decoration: underline;*/
          text-align: left;
          font-size: 17px;
          position: absolute;
          top: -35px;
          left: 0;
          color: #151515;
        }
      }
      &.active {
        background-color: #151515;
        color: #ffffff;
      }
      &:hover {
        background-color: #151515;
        color: #ffffff;
      }
      &.valid {
        margin: 60px 0 0 0;
        border: 2px solid rgba(41, 167, 25, 0.6);

        &.year2024 {
          &::before {
            content: "Validés 2024";
            border-top: 1px solid rgba(49, 49, 49, 0.71);
            width: 100%;
            height: auto;
            padding: 8px 0;
            line-height: 1;
            text-align: left;
            font-size: 17px;
            position: absolute;
            top: -35px;
            left: 0;
            color: #151515;
          }
          & ~ .valid.year2024 {
            margin-top: 1vh;
            &::before {
              display: none;
            }
          }
        }
        &.year2023 {
          &::before {
            content: "Validés 2023";
            border-top: 1px solid rgba(49, 49, 49, 0.71);
            width: 100%;
            height: auto;
            padding: 8px 0;
            line-height: 1;
            text-align: left;
            font-size: 17px;
            position: absolute;
            top: -35px;
            left: 0;
            color: #151515;
          }
          & ~ .valid.year2023 {
            margin-top: 1vh;
            &::before {
              display: none;
            }
          }
        }
        &.year2022 {
          &::before {
            content: "Validés 2022";
            border-top: 1px solid rgba(49, 49, 49, 0.71);
            width: 100%;
            height: auto;
            padding: 8px 0;
            line-height: 1;
            text-align: left;
            font-size: 17px;
            position: absolute;
            top: -35px;
            left: 0;
            color: #151515;
          }
          & ~ .valid.year2022 {
            margin-top: 1vh;
            &::before {
              display: none;
            }
          }
        }
        &.year2021 {
          &::before {
            content: "Validés 2021";
            border-top: 1px solid rgba(49, 49, 49, 0.71);
            width: 100%;
            height: auto;
            padding: 8px 0;
            line-height: 1;
            text-align: left;
            font-size: 17px;
            position: absolute;
            top: -35px;
            left: 0;
            color: #151515;
          }
          & ~ .valid.year2021 {
            margin-top: 1vh;
            &::before {
              display: none;
            }
          }
        }
        &.year2020 {
          &::before {
            content: "Validés 2020";
            border-top: 1px solid rgba(49, 49, 49, 0.71);
            width: 100%;
            height: auto;
            padding: 8px 0;
            line-height: 1;
            text-align: left;
            font-size: 17px;
            position: absolute;
            top: -35px;
            left: 0;
            color: #151515;
          }
          & ~ .valid.year2020 {
            margin-top: 1vh;
            &::before {
              display: none;
            }
          }
        }

        p {
          position: relative;
          opacity: 0.6;
          &::before {
            /*Add another block-level blank space*/
            content: "";
            display: block;
            position: absolute;
            /*Make it a small rectangle so the border will create an L-shape*/
            width: 3px;
            height: 6px;

            /*Add a white border on the bottom and left, creating that 'L' */
            border: solid rgb(27, 107, 16);
            border-width: 0 2px 2px 0;

            /*Rotate the L 45 degrees to turn it into a checkmark*/
            transform: translate(0, -50%) rotate(45deg);

            top: 50%;
            left: 0;
          }
        }
      }
      p {
        padding: 0.8vh 1vw 0.8vh 1vw;
      }
    }

    .load-more {
      margin: 20px 0;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 100%;
      padding: 12px;
      background: #ff0058;
      border-bottom: 2px solid #ff0058;
      border-top-style: none;
      border-right-style: none;
      border-left-style: none;
      color: #fff;
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
</style>

<template>
  <div id="PreviewFormat">
    <PreviewContentFormat />
  </div>
</template>

<script>
import PreviewContentFormat from "../components/Preview/PreviewContentFormat.vue";

export default {
  name: "PreviewFormat",
  props: [],
  components: { PreviewContentFormat },
  async created() {
    try {
      await this.$auth.renewTokens();
      this.$store.dispatch("SET_ACTIVE_USER", this.$auth.profile.sub);
      this.$store.dispatch(
        "LOAD_PREVIEW_FORMAT_DATA",
        window.location.pathname.split("/").pop()
      );
    } catch (e) {
      console.log(e);
      // this.$auth.login({ target: this.$router.history.current.path });
    }
  }
};
</script>

<style scoped lang="scss">
#PreviewFeedback {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}
</style>

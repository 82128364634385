<template>
  <div class="about">
    <h1>Callback Page</h1>
  </div>
</template>
<script>
export default {
  name: "Callback",
  methods: {
    handleLoginEvent(data) {
      this.$router.push(data.state.target || "/");
    }
  },
  created() {
    console.log("created callback");
    this.$auth.handleAuthentication();
  }
};
</script>

import Router from "../../router";
import Password from "./views/Password.vue";
import Callback from "./components/LoginForm/Callback.vue";

Router.addRoutes([
  {
    path: "/retrieve-password",
    name: "Password",
    component: Password
  },
  { path: "/callback", component: Callback }
]);

<template>
  <div class="content">404</div>
</template>

<script>
export default {
  name: "Error404"
};
</script>

<style></style>

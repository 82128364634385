<template>
  <div id="PreviewRegie">
    <PreviewContent />
    <transition name="modal">
      <DeleteFormat v-if="ModalDeleteFormat" />
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PreviewContent from "../components/Preview/PreviewContent.vue";
import DeleteFormat from "../../CoreComponent/components/Modals/DeleteFormat.vue";

export default {
  name: "PreviewFeedback",
  props: [],
  components: {
    PreviewContent,
    DeleteFormat
  },
  computed: {
    ...mapState({
      ModalDeleteFormat: state => state.Home.ModalDeleteFormat,
    })
  },
  async created() {
    try {
      await this.$auth.renewTokens();
      this.$store.dispatch("SET_ACTIVE_USER", this.$auth.profile.sub);
      this.$store.dispatch(
        "LOAD_PREVIEW_DATA",
        window.location.pathname.split("/").pop()
      );
    } catch (e) {
      console.log(e);
      // this.$auth.login({ target: this.$router.history.current.path });
    }
  }
};
</script>

<style scoped lang="scss">
#PreviewFeedback {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

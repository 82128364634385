import Router from "@/router";

export default {
  state: {
    DisplayModalReset: false,
    reset: {},
    loginError: ""
  },
  mutations: {
    SET_TOGGLE_RESET_MODAL: state => {
      state.DisplayModalReset = !state.DisplayModalReset;
    },
    PASSWORD: (state, data) => {
      if (data === "We've just sent you an email to reset your password.") {
        state.reset = {
          value: true,
          data: data
        };
      } else {
        state.reset = {
          value: false,
          data: data
        };
      }
    },
    LOGIN: (state, data) => {
      state.loginError = data.error_description;
    }
  },
  getters: {},
  actions: {
    TOGGLE_RESET_MODAL: ({ commit }) => {
      commit("SET_TOGGLE_RESET_MODAL");
    },
    SEND_REQUEST_RESET: ({ commit }, data) => {
      Router.app.$auth.changePassword(data).then(res => {
        commit("PASSWORD", res);
      });
    },
    LOGIN: ({ commit }, data) => {
      Router.app.$auth.login(data).then(res => {
        // console.log(res);
        commit("LOGIN", res);
      });
    }
  }
};

<template>
  <div
    class="main"
    :class="uploadType === 'updateProject' ? 'update' : 'create'"
  >
    <div class="upload" :class="Loading ? 'load' : null">
      <div class="cont-upload-box">
        <div class="cont-left-upload">
          <h2>{{ text }}:</h2>
          <div class="dropbox">
            <input
              type="file"
              id="file"
              class="input-file"
              ref="file"
              v-on:change="handleFileUpload()"
            />
            <p v-if="!file">Insert project .zip file</p>
            <p v-if="file">{{ file.name }}</p>
            <div class="zip-error-message" v-if="ZipErrors.length">
              <p class="zip-error-notice">
                Le fichier est incorrect:
              </p>
              <ul>
                <li v-for="error of ZipErrors" :key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="submitcomp">
      <div class="submit" v-on:click="submitFile()">generate</div>
      <!--      <div class="submit" v-on:click="checkFile()">generate</div>-->
      <LoadingMickey v-if="Loading" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingMickey from "../../Loader/mickeyLoader/loadingMickey.vue";

export default {
  name: "Upload",
  components: {
    LoadingMickey
  },
  computed: {
    ...mapState({
      activeProject: state => state.Home.activeProject,
      ActiveUser: state => state.Home.ActiveUser,
      Loading: state => state.Home.Loading,
      ZipErrors: state => state.Home.ZipErrors
    })
  },
  props: {
    idProject: String,
    upload: Function,
    text: String,
    uploadType: String
  },
  data: function() {
    return {
      loading: true,
      file: ""
    };
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.$store.dispatch("RESET_ZIP_ERRORS");
    },
    checkFile() {
      console.log(this.file);
    },

    submitFile() {
      console.log(this.file);
      let formData = new FormData();

      console.log(this.file, this.idProject, this.ActiveUser.id);

      switch (this.uploadType) {
        case "createProject":
          formData.append("file", this.file);
          formData.append("projectId", this.idProject);
          formData.append("userId", this.ActiveUser.id);
          this.upload(formData);
          break;
        case "updateProject":
          formData.append("file", this.file);
          formData.append("projectId", this.idProject);
          formData.append("userId", this.ActiveUser.id);
          this.upload(formData);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: auto;
  position: relative;
  .upload {
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin: 1vh 1vw;
    .cont-upload-box {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }
    .cont-left-upload {
      width: 100%;
      h2 {
        font-size: 20px;
      }
    }
    &.load {
      pointer-events: none;
      opacity: 0.3;
    }
  }
  .submit {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    padding: 12px;
    background: #ff0058;
    border-bottom: 2px solid #ff0058;
    border-top-style: none;
    border-right-style: none;
    border-left-style: none;
    color: #fff;
    max-width: 150px;
    cursor: pointer;
    margin-left: 1vw;
    &:hover {
      font-weight: bold;
    }
  }
  &.update {
    .upload {
      margin: 0;
      height: 100%;
      .cont-upload-box {
        .cont-left-upload {
          .dropbox {
            width: 100%;
            height: 85%;
          }
        }
      }
    }
    .submit {
      margin-left: 0;
    }
  }
  &.create {
    .upload {
      .cont-upload-box {
        .cont-left-upload {
          .dropbox {
            height: 400px;
          }
        }
      }
    }
  }
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
}
.dropbox {
  background-color: transparent;
  color: dimgray;
  height: 50px;
  position: relative;
  cursor: pointer;
  border: 2px dashed #151515;
  box-shadow: 0px 0px 1.3vw 0px rgba(0, 0, 0, 0.3);
}
.dropbox p:not(.zip-error-notice) {
  color: #151515;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.dropbox:hover {
  opacity: 0.6;
}

.zip-error-message {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 95%;
  font-size: 0.9vw;

  p,
  ul {
    padding-bottom: 10px;
  }

  li {
    list-style-type: none;
    padding-bottom: 5px;
  }

  p {
    color: #ff0058;
  }
}
</style>

<template>
  <div id="FeedbackResume" :class="Loading ? 'load' : null">
    <p class="stick" @click="toggleFeedback">Retours</p>
    <div class="content">
      <LoadingMickey v-if="Loading" />
      <div class="projects" v-if="previewData.status">
        <h4>Retours Regie</h4>
        <p @click="downloadRegie(previewData)" class="download">
          Télécharger Regie
        </p>
        <div class="cont-text-area">
          <div class="cont-sub-feedback">
            <div class="cont-comment">
              <p
                class="see link-open"
                v-if="previewData.RegieEvenements.length > 0"
                @click="seeOldProject"
              >
                Voir les retours de la Regie
              </p>
              <p class="see link-open no-link" v-else>No previous comment</p>
              <div class="old seeMore" v-if="seePreviewProject">
                <div
                  class="campaignReview"
                  v-for="(review, index) in previewData.RegieEvenements"
                  :key="index"
                  :class="
                    review.User.brand === 'MadMix Digital' ? 'MMD' : 'CLIENT'
                  "
                >
                  <p class="txt-comment">
                    <!-- {{previewData.ReviewProjects[0].author.firstName}} {{previewData.ReviewProjects[0].author.lastName}} -->
                    {{ review.text }}
                  </p>
                  <div class="comment-info">
                    <p class="user">
                      by
                      <span>{{ review.User.firstName }}</span>
                    </p>
                    &nbsp;
                    <p class="time">on {{ pgFormatDate(review.createdAt) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="formatsContainer" v-if="!previewData.status">
        <h4>Retours Formats</h4>
        <div
          class="formatReview"
          id="cont-list-review-format"
          v-for="(spec, index) in previewData.Formats"
          :key="index"
        >
          <h5 v-if="spec.spec !== 'noSpec'">{{ spec.spec }}</h5>
          <div
            class="spec"
            v-for="(format, index2) in spec.formats"
            :key="index2"
            :id="'feedback_' + format.id"
          >
            <FormatReview ref="elemreview" :index="index" :index2="index2" />
          </div>
        </div>
      </div>
    </div>
    <div class="button" v-if="!previewData.status">
      <div class="save" @click="ValidCampaign" v-if="testLast()">
        TOUT VALIDER
      </div>

      <div
        class="save"
        @click="saveReview"
        :class="testSend() ? 'active' : 'not'"
      >
        ENVOYER
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingMickey from "../../../../CoreComponent/components/Loader/mickeyLoader/loadingMickey.vue";
import FormatReview from "./FormatReview.vue";

export default {
  name: "FeedbackResume",
  props: {
    toggleFeedback: Function,
    previewData: Object,
    active: Number
  },
  data() {
    return {
      newCampaignReview: "",
      seePreviewProject: false,
      reviewRegie: {}
    };
  },
  components: {
    FormatReview,
    LoadingMickey
  },
  computed: {
    ...mapState({
      ReviewList: state => state.Home.ReviewList,
      ActiveUser: state => state.Home.ActiveUser,
      Loading: state => state.Home.Loading
    })
  },
  methods: {
    testSend() {
      console.log("testSend()");

      let test = false;
      let count = 0;
      let countRev = 0;

      let valid = true;
      if (this.ActiveUser.brand === "Disney") {
        // console.log("FB this.ActiveUser.brand === Disney "  + countRev + " " + count + " " + valid)

        this.previewData.Formats.map(item => {
          item.formats.map(item1 => {
            // console.log("item1 : " + item1.status)
            if (!item1.status) {
              valid = false;
            }
            if (
              item1.FormatEvenements[item1.FormatEvenements.length - 1].text !==
              "Format Validé"
            ) {
              count++;
            }
          });
        });

        this.ReviewList.map(item => {
          if (item.text !== "" && !item.status) {
            countRev++;
          }
        });
        if (countRev === count || valid) {
          // console.log("countRev === count || valid " + countRev + " " + count + " " + valid)
          test = true;
        }
      } else {
        if (!this.$auth.profile["https://disneyViewer"]) {
          // console.log("FB l'user n'a pas le role disneyViewer")
          test = true;
        }
      }

      // console.log("FB last return test" + countRev + " " + count + " " + valid)
      return test;
    },
    testLast() {
      // return true;
      if (this.ActiveUser.brand === "Disney") {
        return true;
      } else {
        return false;
      }
    },
    seeOldProject() {
      this.seePreviewProject = !this.seePreviewProject;
    },
    downloadRegie(id) {
      let data = {
        Regie: id,
        user: this.ActiveUser
      };
      this.$store.dispatch("DOWNLOAD_REGIE", data);
    },
    saveReview() {
      let test = true;

      // Il doit y avoir peut être plus simple/efficace, mais en attendant, ça fait le taff ;)
      this.$refs.elemreview.forEach(elem => {
        if (elem._vnode.children[1].children) {
          if (this.ActiveUser.brand === "Disney") {
            if (
              elem._vnode.children[1].children[0].data.domProps.value === ""
            ) {
              elem._vnode.children[1].elm.classList.add("wrong");
              elem._vnode.children[1].children[0].elm.classList.add("wrong");
              test = false;
            } else {
              elem._vnode.children[1].elm.classList.remove("wrong");
              elem._vnode.children[1].children[0].elm.classList.remove("wrong");
            }
          }
        }
      });
      // console.log(test);

      if (test) {
        let usr;
        console.log(this.$auth.user);
        if (this.ActiveUser.brand === "MadMix Digital") {
          usr = "MadMix";
        } else if (this.ActiveUser.brand === "Disney") {
          usr = "Client";
        }
        console.log(usr);
        let valid = true;
        this.previewData.Formats.map(item => {
          if (!item.status) {
            valid = false;
          }
        });
        if (valid) {
          this.reviewRegie = {
            text: "Regie Validé",
            author: this.ActiveUser.id,
            regieId: this.previewData.id,
            type: "validation"
          };
        }

        this.$store.dispatch("SAVE_ALL_REVIEW", {
          reviewList: this.ReviewList,
          previewData: this.previewData,
          reviewRegie: this.reviewRegie,
          usr: usr
        });
      }
    },
    pgFormatDate(date) {
      /* Via http://stackoverflow.com/questions/3605214/javascript-add-leading-zeroes-to-date */
      function zeroPad(d) {
        return ("0" + d).slice(-2);
      }
      function getDay(d) {
        var day = d.getDay();
        switch (day) {
          case 0:
            day = "Sunday";
            break;
          case 1:
            day = "Monday";
            break;
          case 2:
            day = "Tuesday";
            break;
          case 3:
            day = "Wednesday";
            break;
          case 4:
            day = "Thursday";
            break;
          case 5:
            day = "Friday";
            break;
          case 6:
            day = "Saturday";
            break;
          default:
            day = "undefined";
            break;
        }
        return day;
      }
      var parsed = new Date(date);
      var format =
        getDay(parsed) +
        " " +
        zeroPad(parsed.getDate()) +
        "." +
        zeroPad(parsed.getMonth() + 1) +
        "." +
        parsed.getUTCFullYear() +
        " at " +
        zeroPad(parsed.getHours()) +
        ":" +
        zeroPad(parsed.getMinutes()) +
        ":" +
        zeroPad(parsed.getSeconds());
      return format;
    },
    ValidCampaign() {
      this.$store.dispatch("VALID_CAMPAIGN");
    }
  },
  watch: {
    active: function(val) {
      // document.querySelectorAll(".formatsContainer").scrollTop();
      // console.log(val);
      // console.log(document.querySelector(".formatsContainer").scrollTop);

      document.querySelector(".formatsContainer").scrollTop = val;
    }
  }
};
</script>

<style scoped lang="scss">
#FeedbackResume {
  height: auto;
  position: relative;
  .stick {
    writing-mode: tb-rl;
    transform: rotate(180deg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ff0058;
    height: 25%;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.5px;
    color: #fff;
    padding: 5px;
    cursor: pointer;
  }
  .content {
    background: #fff;
    height: 100%;
    width: 500px;
    margin: 0;
    padding: 0 0.5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    border-left: 1px solid #151515;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .projects {
      width: 100%;
      margin-bottom: 4vh;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .download {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 100%;
        padding: 12px;
        background: #ff0058;
        border-bottom: 2px solid #ff0058;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        color: #fff;
        max-width: 150px;
        margin-bottom: 15px;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
      h4 {
        font-family: "lato_blackregular";
        letter-spacing: 0.4vw;
        font-size: 1.05vw;
        text-transform: uppercase;
        color: #151515;
      }
      .cont-text-area {
        display: flex;
        flex-direction: column;
        width: 95%;
        textarea {
          border: 1px solid #151515;
          width: calc(100% - 0.4vw);
          padding: 0.2vh 0.2vw;
          resize: vertical;
        }
        .cont-sub-feedback {
          background-color: #151515;
          border: 1px solid #151515;
          border-top: 0;
          color: #ffffff;
          font-weight: 300;
          width: 100%;
          .link-open {
            padding: 0.5vh 0;
            cursor: pointer;
            &.no-link {
              cursor: unset;
              background-color: #cccccc;
            }
            &:hover {
              text-decoration: underline;
              &.no-link {
                text-decoration: none;
              }
            }
          }
          .cont-comment {
            .seeMore {
              background-color: #ff0058;
              color: #ffffff;
              .txt-comment {
                padding: 1vh 0.5vw;
                text-align: left;
                width: 90%;
                word-break: break-word;
              }
              .comment-info {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 0 0.5vw 0.5vh 0.5vw;
                font-size: 0.7vw;
                font-style: italic;
                white-space: pre;
                span {
                  font-style: normal;
                  white-space: pre;
                }
              }
              .MMD {
                background: #ff0058;
                color: #ffffff;
                align-self: flex-end;
                align-items: flex-end;
                .author {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                }
              }
              .CLIENT {
                background: #ffffff;
                color: #151515;
                align-self: flex-start;
                align-items: flex-start;
                .author {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  p {
                    max-width: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .formatsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10vh;
      width: 100%;
      overflow: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      scrollbar-color: transparent transparent;
      &::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      h4 {
        font-family: "lato_blackregular";
        letter-spacing: 0.4vw;
        font-size: 1.05vw;
        text-transform: uppercase;
        color: #151515;
      }
      .formatReview {
        width: 100%;
        h5 {
          font-size: 16px;
          text-align: left;
          margin-bottom: 0;
          position: relative;
          flex: 1;
          flex-grow: 1;
          width: fit-content;
          z-index: 2;
          &::after {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 0;
            width: calc(100% + 28px);
            right: -29px;
            height: 3px;
            background-color: #ff0058;
          }
          &::before {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 0;
            left: calc(100% + 28px);
            height: 0;
            width: 0;
            border-right: 12px solid transparent;
            border-bottom: 10px solid #ff0058;
          }
        }
      }
    }
  }
  .button {
    position: absolute;
    width: calc(100% - 30px);
    bottom: 0;
    right: 0;
    height: 75px;
    border-top: 1px solid #151515;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    .save {
      min-width: 30%;
      max-width: 50%;
      height: 30px;
      background: #ff0058;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      letter-spacing: 1.5px;
      padding: 5px 15px;
      cursor: pointer;
      &.not {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
  &.load {
    pointer-events: none;
    .content {
      .formatsContainer {
        pointer-events: none;
        opacity: 0.3;
      }
    }
    .button {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}
@-moz-document url-prefix() {
  .formatsContainer {
    overflow: hidden !important;
  }
}
</style>

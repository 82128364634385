import axios from "axios";

export default {
  state: {
    ModalCreateUser: false,
    RoleList: [],
    ProjectList: [],
    RegieList: [],
    FormatList: [],
    ActiveProject: undefined,
    ActiveRegie: undefined,
    PreviewRegie: {},
    PreviewFormat: {},
    ModalNewProject: false,
    ModalUpdateProject: false,
    ModalDeleteFormat: false,
    MmdUsers: [],
    DisneyUsers: [],
    ReviewList: [],
    ActiveUser: {},
    Loading: false,
    PreviewPublicRegie: {},
    PreviewPublicFormat: {},
    FormatToDelete: {},
    ProjectsOffset: false,
    ZipErrors: []
  },
  mutations: {
    DEFAULT: () => {},
    DOWNLOAD: () => {},
    SET_TOGGLE_USER_CREATE_MODAL: state => {
      state.ModalCreateUser = !state.ModalCreateUser;
    },
    SET_ROLES_ERROR: (state, data) => {
      state.RoleError = data.response.message;
    },
    SET_PROJECT: (state, data) => {
      state.ProjectList = data;
    },
    SET_PROJECTS_OFFSET: (state, data) => {
      state.ProjectsOffset = data;
    },
    SET_MORE_PROJECT: (state, data) => {
      state.ProjectList = state.ProjectList.concat(data);
    },
    SET_ACTIVE_PROJECT: (state, data) => {
      state.ActiveProject = data;
    },
    SET_ACTIVE_REGIE: (state, data) => {
      if (data === "aucun") {
        state.FormatList = [];
      } else {
        state.ActiveRegie = data;
      }
    },
    SET_REGIE_LIST: (state, data) => {
      state.RegieList = data;
    },
    SET_FORMAT_LIST: (state, data) => {
      state.FormatList = data;
    },
    SET_PREVIEW_REGIE_DATA: (state, data) => {
      data.Formats.map((item, index) => {
        if (index === 0) {
          item.deployPreview = true;
          item.deployFeedback = true;
        } else {
          item.deployPreview = true;
          item.deployFeedback = true;
        }
      });
      state.PreviewRegie = data;
    },
    SET_PREVIEW_REGIE_DATA_PUBLIC: (state, data) => {
      state.PreviewPublicRegie = data;
    },
    SET_PREVIEW_FORMAT_DATA: (state, data) => {
      state.PreviewFormat = data;
    },
    SET_PREVIEW_PUBLIC_FORMAT_DATA: (state, data) => {
      state.PreviewPublicFormat = data;
    },
    SET_TOGGLE_MODAL_NEW_PROJECT: state => {
      state.ModalNewProject = !state.ModalNewProject;
    },
    SET_TOGGLE_MODAL_DELETE_FORMAT: (state, data) => {
      console.log("SET_TOGGLE_MODAL_DELETE_FORMAT", data);
      state.FormatToDelete = data;
      if (state.ModalDeleteFormat) {
        state.FormatToDelete = {};
      }
      state.ModalDeleteFormat = !state.ModalDeleteFormat;
    },
    DOWLOAD: () => {
      console.log("default message");
    },
    SET_MMD_USER: (state, data) => {
      state.MmdUsers = data;
    },
    SET_DISNEY_USER: (state, data) => {
      state.DisneyUsers = data;
    },
    SET_NEW_PROJECT_ITEM: (state, data) => {
      state.ProjectList.unshift(data);
      let idx;
      state.ProjectList.map((item, index) => {
        if (item.id === data.id) {
          idx = index;
        }
      });
      state.ActiveProject = idx;
      state.ActiveRegie = undefined;
      state.RegieList = [];
      state.FormatList = [];
    },
    SET_TOGGLE_UPDATE_MODAL: state => {
      state.ModalUpdateProject = !state.ModalUpdateProject;
    },
    SET_REVIEW_LIST: (state, data) => {
      if (state.ReviewList.length === 0) {
        state.ReviewList.push(data);
      } else {
        let idx;
        let check = false;
        state.ReviewList.map((item, index) => {
          if (data.formatId === item.formatId) {
            idx = index;
            check = true;
          }
        });
        if (check) {
          if (data.text) {
            state.ReviewList[idx] = data;
          } else {
            state.ReviewList.splice(idx, 1);
          }
        } else {
          state.ReviewList.push(data);
        }
      }
    },
    SET_USER_ACTIF: (state, data) => {
      state.ActiveUser = data;
    },
    SET_RESET_COMMENT_IF_EXIST: (state, data) => {
      if (state.ReviewList.length > 0) {
        state.ReviewList.map((item, index) => {
          if (item.formatId === data) {
            state.ReviewList.splice(index, 1);
          }
        });
      }
    },
    SET_VALID_CAMPAIGN: state => {
      state.ReviewList = [];
      state.PreviewRegie.Formats.map((item, id) => {
        // item.status = true;
        item.formats.map((form, id2) => {
          state.PreviewRegie.Formats[id].formats[id2].status = true;
          if (
            state.PreviewRegie.Formats[id].formats[id2].FormatEvenements[
              state.PreviewRegie.Formats[id].formats[id2].FormatEvenements
                .length - 1
            ].text !== "Format Validé"
          ) {
            state.ReviewList.push({
              text: "Format Validé",
              author: state.ActiveUser.id,
              formatId: state.PreviewRegie.Formats[id].formats[id2].id,
              type: "validation"
            });
          }
        });
      });
    },
    TOGGLE_LOADING: state => {
      state.Loading = !state.Loading;
    },
    SET_TOGGLE_PREVIEWDATA_TOGGLE: (state, data) => {
      state.PreviewRegie.Formats[data].deployPreview = !state.PreviewRegie
        .Formats[data].deployPreview;
    },
    REMOVE_FORMAT_TO_DELETE: state => {
      state.FormatToDelete = {};
    },
    SET_ZIP_ERRORS: (state, data) => {
      state.ZipErrors = data;
    }
  },
  getters: {},
  actions: {
    GET_MORE_PROJECT_LIST: ({ commit }, offset = { offset: null }) => {
      // console.log('action GET_MORE_PROJECT_LIST')

      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/projects/?offset=" + offset.offset
        )
        .then(res => {
          commit("SET_MORE_PROJECT", res.data);
          commit("SET_PROJECTS_OFFSET", false);
          return res.data[0];
        })
        .catch(err => {
          console.log(err);
        });
    },
    GET_PROJECT_LIST: ({ commit }) => {
      // console.log('action GET_PROJECT_LIST')
      axios
        .get(process.env.VUE_APP_API_URL + "/api/projects/?offset=null")
        .then(res => {
          commit("SET_PROJECT", res.data);
          commit(
            "SET_PROJECTS_OFFSET",
            res.data.filter(inactiveProject => inactiveProject.status).length
          );
          return res.data[0];
        })
        .then(response => {
          if (response) {
            commit("SET_ACTIVE_PROJECT", 0);
            axios
              .get(process.env.VUE_APP_API_URL + "/api/regies/" + response.id)
              .then(resp => {
                commit("SET_REGIE_LIST", resp.data);
                return resp;
              })
              .then(response1 => {
                if (response1.data.length > 0) {
                  commit("SET_ACTIVE_REGIE", 0);
                  axios
                    .get(
                      process.env.VUE_APP_API_URL +
                        "/api/formats/" +
                        response1.data[0].id
                    )
                    .then(resp => {
                      commit("SET_FORMAT_LIST", resp.data);
                      return resp;
                    });
                } else {
                  commit("SET_ACTIVE_REGIE", "aucun");
                }
              });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    TOGGLE_USER_CREATE_MODAL: ({ commit }) => {
      commit("SET_TOGGLE_USER_CREATE_MODAL");
    },
    TOGGLE_DELETE_FORMAT_MODAL: ({ commit }, data) => {
      console.log("TOGGLE_DELETE_FORMAT_MODAL");
      commit("SET_TOGGLE_MODAL_DELETE_FORMAT", data);
    },
    CREATE_NEW_USER: ({ commit }, data) => {
      axios
        .post(process.env.VUE_APP_API_URL + "/api/users", data)
        .then(() => {
          commit("NEW_USER_CREATED");
        })
        .catch(err => {
          console.log(err);
        });
    },
    UPLOAD_PROJECT_ZIP: ({ commit }, data) => {
      commit("TOGGLE_LOADING");
      axios
        .post(process.env.VUE_APP_API_URL + "/api/upload/project/", data)
        .then(response => {
          if (response.data.isGood === false) {
            // Si il y a une erreur dans le zip, on affiche les erreurs au user
            console.log(response);
            commit("SET_ZIP_ERRORS", response.data.messages);
            commit("TOGGLE_LOADING");
          } else {
            console.log("upload done");
            let pid;
            for (var p of data) {
              if (p[0] === "projectId") {
                pid = p[1];
              }
            }
            axios
              .get(process.env.VUE_APP_API_URL + "/api/regies/" + pid)
              .then(resp => {
                if (resp.data.length > 0) {
                  commit("SET_REGIE_LIST", resp.data);
                  commit("SET_ACTIVE_REGIE", 0);
                  axios
                    .get(
                      process.env.VUE_APP_API_URL +
                        "/api/formats/" +
                        resp.data[0].id
                    )
                    .then(resp => {
                      commit("TOGGLE_LOADING");
                      commit("SET_FORMAT_LIST", resp.data);
                    });
                } else {
                  commit("TOGGLE_LOADING");
                  commit("SET_ACTIVE_REGIE", "aucun");
                }
              });
          }
        })
        .catch(e => console.log(e));
    },
    UPDATE_PROJECT_ZIP: ({ commit }, data) => {
      console.log("je passe ici");

      commit("TOGGLE_LOADING");
      axios
        .post(process.env.VUE_APP_API_URL + "/api/upload/updateProject/", data)
        .then(res => {
          if (res.data.isGood === false) {
            // Si il y a une erreur dans le zip, on affiche les erreurs au user
            console.log(res);
            commit("SET_ZIP_ERRORS", res.data.messages);
            commit("TOGGLE_LOADING");
          } else {
            console.log(res.data);
            let pid;
            for (var p of data) {
              if (p[0] === "projectId") {
                pid = p[1];
              }
            }

            axios
              .get(process.env.VUE_APP_API_URL + "/api/regies/" + pid)
              .then(() => {
                window.location.reload();
              });
          }
        })
        .catch(e => console.log(e));
    },
    CHANGE_ACTIVE_PROJECT: ({ commit }, data) => {
      commit("SET_ACTIVE_PROJECT", data.index);
      axios
        .get(process.env.VUE_APP_API_URL + "/api/regies/" + data.id)
        .then(resp => {
          commit("SET_REGIE_LIST", resp.data);
          return resp;
        })
        .then(response => {
          if (response.data.length > 0) {
            commit("SET_ACTIVE_REGIE", 0);
            axios
              .get(
                process.env.VUE_APP_API_URL +
                  "/api/formats/" +
                  response.data[0].id
              )
              .then(resp => {
                commit("SET_FORMAT_LIST", resp.data);
              });
          } else {
            commit("SET_ACTIVE_REGIE", "aucun");
          }
        });
    },
    CHANGE_ACTIVE_REGIE: ({ commit }, data) => {
      commit("SET_ACTIVE_REGIE", data.index);
      axios
        .get(process.env.VUE_APP_API_URL + "/api/formats/" + data.id)
        .then(resp => {
          commit("SET_FORMAT_LIST", resp.data);
        });
    },
    LOAD_PREVIEW_DATA: ({ commit }, data) => {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/previewRegie/" + data)
        .then(res => {
          commit("SET_PREVIEW_REGIE_DATA", res.data);
        });
    },
    LOAD_PREVIEW_DATA_PUBLIC: ({ commit }, data) => {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/publicPreview/" + data)
        .then(res => {
          commit("SET_PREVIEW_REGIE_DATA_PUBLIC", res.data);
        });
    },
    LOAD_PREVIEW_FORMAT_DATA: ({ commit }, data) => {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/previewFormat/" + data)
        .then(res => {
          commit("SET_PREVIEW_FORMAT_DATA", res.data[0]);
        });
    },
    TOGGLE_MODAL_NEW_PROJECT: ({ commit }) => {
      commit("SET_TOGGLE_MODAL_NEW_PROJECT");
    },
    DOWNLOAD_PROJECT: ({ commit }, data) => {
      var projectName = data.Regie.Project.name;
      var accents =
        "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
      var accentsOut =
        "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
      var str1 = projectName.split("");
      var strLen = str1.length;
      var i;
      for (i = 0; i < strLen; i++) {
        if (accents.indexOf(str1[i]) !== -1) {
          str1[i] = accentsOut[accents.indexOf(str1[i])];
        }
      }
      str1 = str1.join("");

      projectName = str1
        .replace(/^[^A-Z0-9]+/gi, "") //might need polyfill if you need to support older browsers
        .toLowerCase() //lower case everything
        .replace(
          /([^A-Z0-9]+)(.)/gi, //match multiple non-letter/numbers followed by any character
          function() {
            return arguments[2].toUpperCase(); //3rd index is the character we need to transform uppercase
          }
        );

      let name = projectName + ".zip";
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/projects/download/" +
            data.Regie.project,
          {
            responseType: "arraybuffer"
          }
        )
        .then(res => {
          // console.log(res);
          if (navigator.appVersion.toString().indexOf(".NET") > 0) {
            window.navigator.msSaveBlob(new Blob([res.data]), name);
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
          commit("DOWNLOAD");
        })
        .catch(err => {
          console.log("erreur");

          console.log(err);
        });
    },
    DOWNLOAD_REGIE: ({ commit }, data) => {
      var regieName = data.Regie.name;
      var projectName = data.Regie.Project.name;
      var accents =
        "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
      var accentsOut =
        "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
      var str1 = projectName.split("");
      var strLen = str1.length;
      var i;
      for (i = 0; i < strLen; i++) {
        if (accents.indexOf(str1[i]) !== -1) {
          str1[i] = accentsOut[accents.indexOf(str1[i])];
        }
      }
      str1 = str1.join("");
      var str2 = regieName.split("");
      var strLen2 = str2.length;
      var i1;
      for (i1 = 0; i1 < strLen2; i1++) {
        if (accents.indexOf(str2[i1]) !== -1) {
          str2[i] = accentsOut[accents.indexOf(str1[i1])];
        }
      }
      str2 = str2.join("");
      projectName = str1
        .replace(/^[^A-Z0-9]+/gi, "") //might need polyfill if you need to support older browsers
        .toLowerCase() //lower case everything
        .replace(
          /([^A-Z0-9]+)(.)/gi, //match multiple non-letter/numbers followed by any character
          function() {
            return arguments[2].toUpperCase(); //3rd index is the character we need to transform uppercase
          }
        );
      regieName = str2
        .replace(/^[^A-Z0-9]+/gi, "") //might need polyfill if you need to support older browsers
        .toLowerCase() //lower case everything
        .replace(
          /([^A-Z0-9]+)(.)/gi, //match multiple non-letter/numbers followed by any character
          function() {
            return arguments[2].toUpperCase(); //3rd index is the character we need to transform uppercase
          }
        );
      let name = projectName + "_" + regieName + ".zip";
      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/regies/download/" + data.Regie.id,
          {
            responseType: "arraybuffer"
          }
        )
        .then(res => {
          // console.log(res);
          if (navigator.appVersion.toString().indexOf(".NET") > 0) {
            window.navigator.msSaveBlob(new Blob([res.data]), name);
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
          let event = {
            type: "Telechargement",
            text:
              "la regie " +
              data.Regie.name +
              " a été téléchargée par " +
              data.user.firstName +
              " " +
              data.user.lastName,
            author: data.user.id,
            regieId: data.Regie.id
          };
          axios
            .post(process.env.VUE_APP_API_URL + "/api/regieEvenement", event)
            .then(() => {
              commit("DOWNLOAD");
            });
        })
        .catch(err => {
          console.log("erreur");

          console.log(err);
        });
    },
    GET_USERS_DISNEY_AND_MMD: ({ commit }) => {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/users/brand/MadMix%20Digital")
        .then(mmd => {
          commit("SET_MMD_USER", mmd.data);
          axios
            .get(process.env.VUE_APP_API_URL + "/api/users/brand/Disney")
            .then(disney => {
              commit("SET_DISNEY_USER", disney.data);
            });
        });
    },
    CREATE_PROJECT: ({ commit }, data) => {
      axios
        .post(process.env.VUE_APP_API_URL + "/api/projects", data)
        .then(res => {
          commit("SET_NEW_PROJECT_ITEM", res.data);
        });
    },
    TOGGLE_UPDATE_MODAL: ({ commit }) => {
      commit("SET_TOGGLE_UPDATE_MODAL");
    },
    CONFIRM_REVIEW: ({ commit }, data) => {
      commit("SET_REVIEW_LIST", data);
    },
    SET_ACTIVE_USER: ({ commit }, data) => {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/users/" + data)
        .then(res => {
          commit("SET_USER_ACTIF", res.data[0]);
        });
    },
    RESET_COMMENT_IF_EXIST: ({ commit }, data) => {
      commit("SET_RESET_COMMENT_IF_EXIST", data);
    },
    SAVE_ALL_REVIEW: ({ commit, state }, data) => {
      commit("TOGGLE_LOADING");

      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/regieEvenement",
          data.reviewRegie
        )
        .then(() => {
          axios
            .post(
              process.env.VUE_APP_API_URL + "/api/formatEvenement",
              data.reviewList
            )
            .then(() => {
              let formats = [];
              data.previewData.Formats.map(item => {
                item.formats.map(form => {
                  formats.push(form);
                });
              });
              axios
                .put(process.env.VUE_APP_API_URL + "/api/formats", formats)
                .then(() => {
                  let status = true;
                  let project = {
                    ...data.previewData
                  };
                  formats.map(format => {
                    if (!format.status) {
                      status = false;
                    }
                  });
                  project.status = status;
                  axios
                    .put(process.env.VUE_APP_API_URL + "/api/regies/", project)
                    .then(() => {
                      if (project.status) {
                        let event = {
                          type: "Validation",
                          text:
                            "la regie " +
                            project.name +
                            " a été validée par " +
                            state.ActiveUser.firstName +
                            " " +
                            state.ActiveUser.lastName,
                          author: state.ActiveUser.id,
                          regieId: project.id
                        };
                        axios
                          .post(
                            process.env.VUE_APP_API_URL + "/api/regieEvenement",
                            event
                          )
                          .then(() => {
                            commit("DOWNLOAD");
                          });
                      }
                      axios
                        .get(
                          process.env.VUE_APP_API_URL +
                            "/api/mail/" +
                            project.id +
                            "/" +
                            data.usr
                        )
                        .then(res => {
                          if (res.data.Status[0].Status === "Success") {
                            console.log("mail send");
                            axios
                              .get(
                                process.env.VUE_APP_API_URL +
                                  "/api/regies/" +
                                  state.PreviewRegie.Project.id
                              )
                              .then(resp2 => {
                                let valid = true;
                                let project = resp2.data[0].Project;
                                resp2.data.map(item => {
                                  if (item.status === false) {
                                    valid = false;
                                  }
                                });

                                if (valid) {
                                  project.status = true;
                                  axios
                                    .put(
                                      process.env.VUE_APP_API_URL +
                                        "/api/projects",
                                      project
                                    )
                                    .then(() => {
                                      commit("TOGGLE_LOADING");
                                      window.location.reload();
                                    });
                                } else {
                                  window.location.reload();
                                }
                              });
                          } else {
                            console.log("mail not send");
                          }
                        });
                    });
                });
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    SAVE_ONE_REVIEW: ({ commit, state }, data) => {
      axios
        .post(process.env.VUE_APP_API_URL + "/api/formatEvenement/one", data)
        .then(res => {
          console.log(res);
          if (res.data.state !== "notcompleted") {
            window.location.reload();
          }
        });
    },
    // eslint-disable-next-line no-unused-vars
    DELETE_ONE_FORMAT: async ({ commit, state }, data) => {
      console.log("delete one");
      await commit("REMOVE_FORMAT_TO_DELETE");
      axios
        .delete(
          process.env.VUE_APP_API_URL +
            "/api/formats/" +
            data.id +
            "/" +
            data.email,
          data
        )
        .then(res => {
          console.log(res);
          if (res.data.state !== "notcompleted") {
            window.location.reload();
          }
        });
    },
    VALID_CAMPAIGN: ({ commit }) => {
      commit("SET_VALID_CAMPAIGN");
    },
    TOGGLE_PREVIEWDATA_TOGGLE: ({ commit }, data) => {
      commit("SET_TOGGLE_PREVIEWDATA_TOGGLE", data);
    },
    SAVE_RENDERDATE: ({ commit, state }) => {
      commit("DEFAULT");
      let data = {
        id: state.FormatList[0].Regie.id,
        Comment: state.FormatList[0].Regie.Comment
      };

      axios.put(process.env.VUE_APP_API_URL + "/api/regies/", data).then(() => {
        window.location.reload();
      });
    },
    LOAD_PREVIEW_PUBLIC_FORMAT_DATA: ({ commit }, data) => {
      axios
        .get(process.env.VUE_APP_API_URL + "/api/previewPublicFormat/" + data)
        .then(res => {
          commit("SET_PREVIEW_PUBLIC_FORMAT_DATA", res.data[0]);
        });
    },
    RESET_ZIP_ERRORS: ({ commit }) => {
      commit("SET_ZIP_ERRORS", []);
    }
  }
};

<template>
  <div class="home" v-if="$auth">
    <Header :auth="isAuthenticated" :user="ActiveUser" />
    <div class="homeContainer">
      <ProjectBar
        :ProjectList="ProjectList"
        :click="changeActiveProject"
        :ActiveProject="ActiveProject"
        :user="ActiveUser"
        :ProjectsOffset="ProjectsOffset"
      />
      <RegieBar
        v-if="ActiveProject >= 0"
        :id="ProjectList[ActiveProject].id"
        :RegieList="RegieList"
        :ActiveRegie="ActiveRegie"
        :click="changeActiveRegie"
        :user="ActiveUser"
      />
      <FormatBar
        v-if="ActiveProject >= 0"
        :RegieList="RegieList"
        :FormatList="FormatList"
        :Project="ProjectList[ActiveProject]"
        :user="ActiveUser"
        :ActiveRegie="ActiveRegie"
      />
    </div>
    <transition name="modal">
      <NewProject v-if="ModalNewProject" />
      <UpdateProject v-if="ModalUpdateProject" />
      <NewUser v-if="ModalCreateUser" />
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src/run/media/fabien/DATA/Work/08-globalTools/Application/front-end/src/modules/TemplateModuleToCopyPast/components/HelloWorld.vue
import { mapState } from "vuex";
import Header from "../../CoreComponent/components/Header/Header.vue";
import ProjectBar from "../components/ProjectBar/ProjectBar.vue";
import RegieBar from "../components/RegieBar/RegieBar.vue";
import FormatBar from "../components/FormatBar/FormatBar.vue";
import NewProject from "../../CoreComponent/components/Modals/NewProjects.vue";
import UpdateProject from "../../CoreComponent/components/Modals/UpdateProject.vue";
import NewUser from "../../CoreComponent/components/Modals/NewUser.vue";

export default {
  name: "home",
  components: {
    Header,
    ProjectBar,
    RegieBar,
    FormatBar,
    NewProject,
    UpdateProject,
    NewUser,
  },
  data() {
    return {
      isAuthenticated: false,
    };
  },
  computed: {
    ...mapState({
      ProjectList: state => state.Home.ProjectList,
      RegieList: state => state.Home.RegieList,
      FormatList: state => state.Home.FormatList,
      ActiveProject: state => state.Home.ActiveProject,
      ActiveRegie: state => state.Home.ActiveRegie,
      ModalNewProject: state => state.Home.ModalNewProject,
      ModalUpdateProject: state => state.Home.ModalUpdateProject,
      ModalCreateUser: state => state.Home.ModalCreateUser,
      ModalDeleteFormat: state => state.Home.ModalDeleteFormat,
      ActiveUser: state => state.Home.ActiveUser,
      ProjectsOffset : state => state.Home.ProjectsOffset
    })
  },
  async created() {
    try {
      await this.$auth.renewTokens();
      this.$store.dispatch("SET_ACTIVE_USER", this.$auth.profile.sub);
      document.title = "DisneyLand Paris | Review";
    } catch (e) {
      this.$auth.login({ target: this.$router.history.current.path });
    }
    this.$store.dispatch("GET_PROJECT_LIST");
  },
  methods: {
    handleLoginEvent(data) {
      this.isAuthenticated = data.loggedIn;
    },
    changeActiveProject(index, id) {
      this.$store.dispatch("CHANGE_ACTIVE_PROJECT", { index, id });
    },
    changeActiveRegie(index, id) {
      this.$store.dispatch("CHANGE_ACTIVE_REGIE", { index, id });
    },
  }
};
</script>
<style scoped lang="scss">
@import "../../CoreComponent/style/variable.scss";
@import "../../CoreComponent/style/modal.scss";
.homeContainer {
  width: 100%;

  min-height: calc(100vh - 30px);
  display: flex;
  flex-direction: row;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

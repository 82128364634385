import Router from "../../router";
import Login from "./views/Login.vue";
import Callback from "./components/LoginForm/Callback.vue";

Router.addRoutes([
  {
    path: "/login",
    name: "login",
    component: Login
  },
  { path: "/callback", component: Callback }
]);

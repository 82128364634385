<template>
  <div id="PreviewPublicContentFormat" v-if="PreviewFormat.Regie">
    <div class="head">
      <h3>{{ PreviewFormat.Regie.Project.name }}</h3>
      <h4>{{ PreviewFormat.Regie.name }}</h4>
    </div>
    <div class="content">
      <div class="iframe">
        <h5>
          {{ PreviewFormat.size }} {{ PreviewFormat.note ? "-" : null }}
          {{ PreviewFormat.note }}
        </h5>
        <img
          :src="PreviewFormat.url + '?test=' + Date.now()"
          :style="{
            width: PreviewFormat.size.split('x')[0] + 'px',
            height: PreviewFormat.size.split('x')[1] + 'px'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PreviewPublicContentFormat",
  props: [],
  computed: {
    ...mapState({
      PreviewFormat: state => state.Home.PreviewPublicFormat
    })
  },
  data() {
    return {
      text: "",
      auth: false,
      deploy: true
    };
  },
  methods: {
    toggleFeedback() {
      this.deploy = !this.deploy;
    },
    addCommentToFormat() {
      let data = [
        {
          formatId: this.previewFormat.id,
          text: this.text,
          author: this.activeUser.id
        }
      ];
      this.$store.dispatch("SAVE_ALL_REVIEW", data);
      this.text = "";
    },
    scrollToMax() {
      setTimeout(() => {
        let maxScroll = this.$refs.innerreview.offsetHeight;
        this.$refs.review.scrollTop = maxScroll;
      }, 100);
    }
  },
  created() {
    if (this.$auth.isAuthenticated) {
      this.auth = true;
    } else {
      this.auth = false;
    }
    // this.scrollToMax();
  },
  watch: {
    PreviewFormat: function(val) {
      document.title = val.note;
    }
  }
};
</script>

<style scoped lang="scss">
#PreviewPublicContentFormat {
  .head {
    position: relative;
    width: 80vw;
    height: auto;
    margin: 4vh auto 4vh auto;
    h3 {
      width: 100%;
      text-align: initial;
      font-family: "lato_blackregular";
      font-size: 5vw;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      color: #151515;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      letter-spacing: 1.5vw;
      opacity: 0.2;
      margin-top: 1vh;
      color: #ffffff;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #151515;
    }
    h4 {
      font-size: 3vw;
      letter-spacing: 0.8vw;
      display: flex;
      line-height: 3vw;
      color: #151515;
      font-family: "latoregular";
      font-weight: 900;
      position: absolute;
      bottom: 10px;
      left: 0;
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
      margin: 0;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: left;
    width: 80vw;
    height: auto;
    margin: auto;
    img {
      display: block;
      margin: 10px 20px;
      border: 1px solid #151515;
    }
    h5 {
      position: relative;
      display: inline-block;
      text-align: left;
      font-family: "latobold";
      font-size: 1.05vw;
      color: #151515;
      margin: 0;
      padding: 0;
      margin-left: 20px;
      margin-bottom: 1vh;
      &:after {
        content: "";
        z-index: -1;
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #ff0058;
      }
    }
  }
  .cont-review {
    width: 80vw;
    height: auto;
    margin: auto;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    .addReview {
      width: 100%;
      textarea {
        width: 100%;
      }
    }
    .review {
      position: relative;
      width: calc(100% - 1vw);
      max-height: 30vh;
      margin: 2vh auto;
      padding: 0 0.5vw;
      border: 1px solid #151515;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .inner-review {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 40%;
          margin: 0.5vh 0;
          border: 1px solid #ff0058;
          .txt-comment {
            padding: 1vh 0.5vw;
            text-align: left;
          }
          .comment-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 0 0.5vw 0.5vh 0.5vw;
            font-size: 0.7vw;
            font-style: italic;
            span {
              font-style: normal;
            }
          }
          &.MMD {
            background: #ff0058;
            color: #ffffff;
            align-self: flex-end;
            align-items: flex-end;
            .author {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            }
          }
          &.CLIENT {
            background: #ffffff;
            color: #151515;
            align-self: flex-start;
            align-items: flex-start;
            .author {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              p {
                max-width: 50%;
              }
            }
          }
        }
      }
    }
  }
  .FeedbackComp {
    position: fixed;
    height: 100vh;
    width: auto;
    background: transparent;
    right: calc(-500px - 1vw);
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s;
    &.active {
      right: 0;
    }
  }
}
</style>

import auth0 from "auth0-js";
import { EventEmitter } from "events";
import axios from "axios";

// var request = require("request");
// import authConfig from "../../auth_config.json";

const webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URL,
  clientID: process.env.VUE_APP_AUTH_CLIENT_ID,
  responseType: "token id_token",
  scope: "openid profil email picture app_metadata read:users_app_metadata"
});

const localStorageKey = "loggedIn";
const loginEvent = "loginEvent";

class AuthService extends EventEmitter {
  login(customState) {
    return new Promise(resolve => {
      var options = {
        domain: process.env.VUE_APP_AUTH_DOMAIN,
        clientID: process.env.VUE_APP_AUTH_CLIENT_ID,
        grant_type: "password",
        email: customState.email,
        password: customState.password,
        scope:
          "openid profile email picture app_metadata read:users_app_metadata",
        client_id: "oaKaQjyq4azQCKgDGUcsv34RfaOvYAfG",
        realm: "Username-Password-Authentication"
      };
      webAuth.login(options, err => {
        if (err) {
          // console.log(err);
          return resolve(err);
        }
        resolve("ok");
      });
    });
  }

  logout() {
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem("idToken");
    localStorage.removeItem("tokenExpiry");
    localStorage.removeItem("profile");
    webAuth.logout({
      returnTo: `${process.env.VUE_APP_AUTH_LOGOUT_URL}`
    });
    this.emit(loginEvent, {
      loggedIn: false
    });
  }

  handleAuthentication(body) {
    return new Promise((resolve, reject) => {
      webAuth.parseHash(
        {
          hash: body
        },
        (err, authResult) => {
          if (err) {
            this.emit(loginEvent, {
              loggedIn: false,
              error: err,
              errorMsg: err.statusText
            });
            reject(err);
          } else {
            this.localLogin(authResult);
            resolve(authResult.idToken);
          }
        }
      );
    });
  }

  isAuthenticated() {
    return (
      Date.now() < localStorage.getItem("tokenExpiry") &&
      localStorage.getItem("loggedIn") === "true"
    );
  }

  isIdTokenValid() {
    return (
      localStorage.getItem("tokenExpiry") &&
      localStorage.getItem("idToken") &&
      Date.now() < localStorage.getItem("tokenExpiry")
    );
  }

  getIdToken() {
    return new Promise((resolve, reject) => {
      if (this.isIdTokenValid()) {
        resolve(localStorage.getItem("tokenExpiry"));
      } else if (this.isAuthenticated()) {
        this.renewTokens().then(authResult => {
          resolve(authResult.idToken);
        }, reject);
      } else {
        resolve();
      }
    });
  }

  localLogin(authResult) {
    // Convert the expiry time from seconds to milliseconds,
    // required by the Date constructor
    this.expiresAt = authResult.idTokenPayload.exp * 1000;
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    localStorage.setItem("idToken", authResult.idToken);
    localStorage.setItem("tokenExpiry", authResult.idTokenPayload.exp * 1000);
    localStorage.setItem(localStorageKey, "true");
    localStorage.setItem("profile", JSON.stringify(authResult.idTokenPayload));
    axios.defaults.headers.common.Authorization = `Bearer ${authResult.idToken}`;
    if (window.location.pathname !== "/login") {
      this.emit(loginEvent, {
        loggedIn: true,
        profile: authResult.idTokenPayload,
        state: authResult.appState || {}
      });
    } else {
      window.location.pathname = "/";
    }
  }

  renewTokens() {
    return new Promise((resolve, reject) => {
      webAuth.checkSession(
        {
          scope: "openid profile email picture"
        },
        (err, authResult) => {
          if (err) {
            reject(err);
          } else {
            this.localLogin(authResult);
            resolve(authResult);
          }
        }
      );
    });
  }

  changePassword(email) {
    return new Promise((resolve, reject) => {
      webAuth.changePassword(
        {
          connection: "Username-Password-Authentication",
          responseType: "token",
          email: email
        },
        (err, resp) => {
          if (err) {
            reject(err);
          } else {
            resolve(resp);
          }
        }
      );
    });
  }
}

const service = new AuthService();

service.setMaxListeners(10);

export default service;

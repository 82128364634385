<template>
  <div id="PreviewFormat">
    <PreviewPublicContentFormat />
  </div>
</template>

<script>
import PreviewPublicContentFormat from "../components/Preview/PreviewPublicContentFormat.vue";

export default {
  name: "PreviewFormat",
  props: [],
  components: { PreviewPublicContentFormat },
  async created() {
    try {
      this.$store.dispatch(
        "LOAD_PREVIEW_PUBLIC_FORMAT_DATA",
        window.location.pathname.split("/").pop()
      );
    } catch (e) {
      console.log(e);
      // this.$auth.login({ target: this.$router.history.current.path });
    }
  }
};
</script>

<style scoped lang="scss">
#PreviewFeedback {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}
</style>
